import HomeRules from '../../../Components/HouseRules/HouseRules';
import './HomeRulesPage.scss';

const HomeRulesPage = () => {
    return(
        <div className="house-rules-page">
            <HomeRules />
        </div>
    )
}

export default HomeRulesPage;