import { FormikValues, useFormik } from "formik"
import Title from "../../../Components/Title/Title.component"
import Layout from "../../../Views/Admin/Layout"
import { RoleModule } from "../../../Components/RoleModule/RoleModule.component";
import { permissionsTree } from "../../../constants/permissions.constant";
import Input from "../../../Components/Input/Input.component";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { StyledButton } from "../../../Components/Button/Button.styles";
import * as Yup from 'yup';
import './UserRolesCreate.scss';

const validationSchema = Yup.object({
    name: Yup.string().required("Role name is required"),
});

export const UserRolesCreate = () => {
    const navigate = useNavigate()
    const [error, setError] = useState<string | null>(null);

    const sendResponse = (values:FormikValues) => {
        const {name,roles} = values || {};
        axios.post('/roles',{
            name,
            permissions: roles,
        }).then(() => {
            navigate('/admin/user-roles')
        }).catch((e: AxiosError) => {
            setError(e.message)
        })
    }

    const formik = useFormik({
        initialValues:{
            name:"",
            roles:[] as string[],
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            sendResponse(values)
        }
    })
    
    return(
        <Layout>
            <Title title="Add Role"/>
            <form className="create-roles" onSubmit={formik.handleSubmit}>
                <Input 
                    label="Role name"
                    type='text'
                    placeholder='Type name "Content Manager"'
                    className='loginForm__input--noRounded'
                    name='name'
                    error={formik.touched.name && formik.errors.name}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                />
                <div className="create-roles__content">
                    {
                        permissionsTree.map((permission) => 
                            <RoleModule
                                key={permission.id} 
                                title={permission.title} 
                                permissions={permission.permissions} 
                                roles={permission.roles}
                                setField={formik.setFieldValue}
                                formRoles={formik.values.roles}
                                handleChange={formik.handleChange}
                            />
                        )
                    }
                </div>
                <div className='flex flex-jcsb mt-20'>
                    <div className='error-message'>{error}</div>
                    <StyledButton type={'submit'} variant='red' className='sm'>create role</StyledButton>
                </div>
            </form>
        </Layout>
    )
}

