import { useEffect, useState } from "react";
import Title from "../../../Components/Title/Title.component";
import Layout from "../../../Views/Admin/Layout"
import { ISentInvites, IUser } from "../../../utils/interfaces/user.interface";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import axios, { AxiosResponse } from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import EndMessage from "../../../Components/EndMessage/EndMessage.component";
import InvitesListComponent from "../../../Components/InvitesList/InvitesList.component";
import ConfirmPopup from "../../../Components/ConfirmPopup/ConfirmPopup";
import InviteMemberPopup from "../../../Components/InviteMemberPopup/InviteMemberPopup";
import { StyledButton } from "../../../Components/Button/Button.styles";
import { Loader } from "../../../Components/Loader/Loader.component";
import { PermissionGuard } from "../../../Components/PermissionGuard/PermissionGuard.component";
import { PermissionsEnum } from "../../../utils/interfaces/permissions.enum";
import { usePermissionGuard } from "../../../hooks/usePermissionsGuard";

const InvitesPage = () => {
    const canCancel = usePermissionGuard(PermissionsEnum.CANCEL_INVITE)
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [invites, setInvites] = useState<ISentInvites[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState<string | null>(searchParams.get("status"))
    const [page, setPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [value] = useDebounce(searchText, 300)
    const [cancelInviteId, setCancelInviteId] = useState<string | null>(null)
    const [isInvitesPopupOpen, setIsInvitesPopupOpen] = useState<boolean>(false)

    const getInvites = async (action: 'next' | null = null, newPage?: number | null) => {
        let url: string = `invites?limit=10&page=${newPage ? newPage : page}`;

        value && (url += `&search=${value}`)
        
        status && (url += `&status=${status}`)

        await axios.get(url)
            .then((res: AxiosResponse<ISentInvites[]>) => {
                const { data } = res;
                
                data.length < 10 && setHasMore(false)
                action === 'next' ? setInvites([...invites, ...data]) : setInvites(data);

                setPage(page + 1)
            })
    }

    const changeState = (status: string) => (): void => {
        const currentStatus = searchParams.get("status")
        const isSameStatus = currentStatus === status;

        if(isSameStatus) return;

        setStatus(status)
        setPage(1)
        setInvites([])
        setHasMore(true)
        setSearchParams({status})
    }

    const searching = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setPage(1)
        setSearchText(e.target.value)
    };

    const onCancel = (id: string): void => {
        setCancelInviteId(id)
        setIsPopupOpen(true)
    }

    const onNewInvite = () => {
        setIsInvitesPopupOpen(false)

        getInvites(null, 1)
    }

    const onPopupAction = (trueOrFalse: boolean): void => {
        setIsPopupOpen(false)
        if(trueOrFalse) {
            axios.delete(`invites/${cancelInviteId}`)
                .then(() => setInvites(invites.filter((invite: ISentInvites) => invite.id !== cancelInviteId)))
        }
    }

    useEffect(() => {
        getInvites();
        // eslint-disable-next-line
    }, [value, setSearchParams])

    return (
        <Layout>
            <PermissionGuard role={PermissionsEnum.INVITE_MEMBERS_PAGE_VIEW}>
                <>
                    <Title title={'Invites'}>
                        <PermissionGuard role={PermissionsEnum.INVITE_MEMBER}>
                            <StyledButton variant="red" onClick={() => setIsInvitesPopupOpen(true)} className="sm">Invite member</StyledButton>
                        </PermissionGuard>
                    </Title>

                    <div className="navigation">
                        <div className='navigation__container'>
                            <div onClick={changeState('INVITED')} className={`navigation__item ${status === 'INVITED' && 'active'}`}>Invited</div>
                            <div onClick={changeState('REGISTERED')} className={`navigation__item ${status === 'REGISTERED' && 'active'}`}>Registered</div>
                        </div>
                        <input type='text' onChange={searching} value={searchText ? searchText : ''} placeholder='Search invite...' className="navigation__search" />
                    </div>

                    <ConfirmPopup 
                        label={'Are you sure you want to cancel this invite?'}
                        text={'This invite will be canceled and the user will not be able to register.'}
                        onAction={onPopupAction}
                        isopen={isPopupOpen}
                    />

                    <InviteMemberPopup 
                        isopen={isInvitesPopupOpen}
                        label="Invite new member"
                        onAction={() => setIsInvitesPopupOpen(false)}
                        onAdd={onNewInvite}
                    />

                    {
                            <InfiniteScroll
                                dataLength={invites.length} 
                                next={() => getInvites('next')}
                                hasMore={hasMore}
                                loader={<Loader />}
                                endMessage={<EndMessage />}
                            >
                                {
                                    invites.map((invite: ISentInvites) => (
                                        <InvitesListComponent 
                                            key={invite.id}
                                            email={invite.email}
                                            request_by={invite.invite_by as IUser}
                                            id={invite.id}
                                            status={invite.status}
                                            onCancel={onCancel}
                                            canCancel={canCancel}
                                        />
                                    ))
                                }
                            </InfiniteScroll>
                    }
                </>
            </PermissionGuard>
        </Layout>
    );
}

export default InvitesPage;