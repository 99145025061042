import { useSearchParams } from "react-router-dom"
import Title from "../../../Components/Title/Title.component"
import Layout from "../../../Views/Admin/Layout"
import { useEffect, useState } from "react"
import { useDebounce } from "use-debounce"
import axios, { AxiosResponse } from "axios"
import InfiniteScroll from "react-infinite-scroll-component"
import EndMessage from "../../../Components/EndMessage/EndMessage.component"
import events from "events"
import { Loader } from "../../../Components/Loader/Loader.component"
import ItemBlock from "../../../Components/ItemBlock/ItemBlock.component"
import { IItemBlock } from "../../../Components/ItemBlock/ItemBlock.interface"
import { usePermissionGuard } from "../../../hooks/usePermissionsGuard"
import { PermissionsEnum } from "../../../utils/interfaces/permissions.enum"

export const LostAndFound = () => {
    const [items, setItems] = useState<IItemBlock[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState<string | null>(searchParams.get("status"))
    const [page, setPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [value] = useDebounce(searchText, 300);
    const canSeePage = usePermissionGuard(PermissionsEnum.SHOW_LOST_AND_FOUND);
    const canUpdate = usePermissionGuard(PermissionsEnum.UPDATE_LOST_AND_FOUND_STATUS);

    const getItems = async (action?: 'next' | null) => {
        let url: string = `lostandfound?limit=10&page=${page}`;

        value && (url += `&search=${value}`);

        status && status !== 'ALL' && (url += `&status=${status}`);

        await axios.get(url)
            .then((res: AxiosResponse) => {
                const { data } = res;

                data.length < 10 && setHasMore(false)
                action === 'next' ? setItems([...items, ...data]) : setItems(data);

                setPage(page + 1)
            })
    }

    const changeState = (status: string) => (): void => {
        const currentStatus = searchParams.get("status")
        const isSameStatus = currentStatus === status;

        if (isSameStatus) return;

        setStatus(status)
        setPage(1)
        setItems([])
        setHasMore(true)
        setSearchParams({ status })
    }

    const searching = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setPage(1)
        setSearchText(e.target.value)
    };

    useEffect(() => {
        if(canSeePage) getItems();
        // eslint-disable-next-line
    }, [value, searchParams])

    return (
        <Layout>
            {canSeePage && <>
                <Title title={'Lost & Found'} />
                <div className="navigation">
                    <div className='navigation__container'>
                        <div onClick={changeState('ALL')} className={`navigation__item ${status === 'ALL' && 'active'}`}>All</div>
                        <div onClick={changeState('lost')} className={`navigation__item ${status === 'lost' && 'active'}`}>Lost</div>
                        <div onClick={changeState('found')} className={`navigation__item ${status === 'found' && 'active'}`}>Found</div>
                    </div>
                    <input type='text' onChange={searching} value={searchText ?? ''} placeholder='Search items...' className="navigation__search" />
                </div>
                {
                    <InfiniteScroll
                        dataLength={events.length}
                        next={() => getItems('next')}
                        hasMore={hasMore}
                        loader={<Loader />}
                        endMessage={<EndMessage />}
                    >
                        {
                            items.map((item: IItemBlock) => (
                                <ItemBlock
                                    key={item.id}
                                    id={item.id}
                                    fullName={item.fullName}
                                    phone={item.phone}
                                    email={item.email}
                                    status={item.status}
                                    canUpdate={canUpdate}
                                />
                            ))
                        }
                    </InfiniteScroll>
                }
            </>}
        </Layout>
    )
}