import { IUser } from '../../utils/interfaces/user.interface';
import RequestComponent from '../RequestComponent/RequestComponent';
import './UserInfo.scss';
import { StyledButton } from '../Button/Button.styles';
import SocialIconsComponent from '../SocialIcons/SocialIcons.component';
import { NavLink, useLocation } from 'react-router-dom';

export interface IUserInfoComponentProps {
  id: string;
  full_name: string;
  email: string;
  socials: string[] | null;
  avatar: string;
  request_by?: IUser | null;
  canSeeMembers?: boolean;
}

const UserInfoComponent: React.FC<IUserInfoComponentProps> = ({
  full_name,
  email,
  id,
  socials,
  avatar,
  request_by,
  canSeeMembers
}) => {
  const location = useLocation();
  
  const currentLocation = location.pathname + location.search;
  
  return (
    <div className="nw-userInfo">
      {request_by && (
        <RequestComponent
          image={`${process.env.REACT_APP_HOST}/public/users/${request_by?.profile_photo}`}
          text={request_by?.full_name}
        />
      )}

      <NavLink to={canSeeMembers?`/admin/members/${id}`: currentLocation} className="nw-userInfoContainer">
        <div className="nw-userInfo__container">
          {
            avatar && 
            <div className="nw-userInfo__avatar">
              <img
                src={`${process.env.REACT_APP_HOST}/public/users/${avatar}`}
                alt="avatar"
              />
            </div>
          }
          <div className="nw-userInfo__text nw-userInfo__text--mobile">
            <div className="nw-userInfo__text-title">Full name</div>
            <div className="nw-userInfo__text-text">{full_name}</div>
          </div>
          <div className="nw-userInfo__text">
            <div className="nw-userInfo__text-title">Email</div>
            <div className="nw-userInfo__text-text">{email}</div>
          </div>
          <div className="nw-userInfo__text">
            <div className="nw-userInfo__text-title">Socials</div>
            <div className="nw-userInfo__text-text">
              <SocialIconsComponent socialLinks={socials} />
            </div>
          </div>
        </div>
        <div className="nw-userInfo__actions">
          {
            canSeeMembers && 
              <StyledButton variant="disabled">
                View Profile
              </StyledButton>
          }
        </div>
      </NavLink>
    </div>
  );
};

export default UserInfoComponent;
