import  styledComponent from "styled-components";
import { NavLink, useSearchParams } from "react-router-dom";
import { LangsContext } from "../../context/LangsContext";
import { useContext } from "react";


const SuccessPagestyles = styledComponent.div`
    text-align: center;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .text:nth-child(2) {
        margin-top: 30px;
        margin-bottom: 60px;
    }
`

const SuccessPage: React.FC = () => {
    const [searchParams] = useSearchParams();

    const content = useContext(LangsContext);

    return (
        <SuccessPagestyles>
            {searchParams.get('view') === 'forgot-password' && (
                <>
                    <div className="text text--lg text--center text--bold">
                        {content?.RECOVER_LINK_SENT || 'Recover link sent to the email.'}
                    </div>                

                    <div className="text text--sm text--center">
                        {content?.CHECK_EMAIL_AND_RESET || 'Check your inbox and reset password.'}
                    </div>

                    <NavLink to="/login" className='loginForm__link loginForm__link--login'>
                        {content?.LOGIN || 'Login'}
                    </NavLink>
                </>
            )}

            {searchParams.get('view') === 'recover-password' && (
                <>
                    <div className="text text--lg text--center text--bold">
                        {content?.PASSWORD_RECOVERED || 'Password recovered successfully.'}
                    </div>                

                    <div className="text text--center text--sm">
                        {content?.GO_TO_LOGIN || 'Go to login and use new credentials.'}
                    </div>

                    <NavLink to="/login" className='loginForm__link loginForm__link--login'>
                        {content?.LOGIN || 'Login'}
                    </NavLink>
                </>
            )}

        </SuccessPagestyles>
    )
}

export default SuccessPage;