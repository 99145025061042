import './ResidentsCreate.scss';

import Title from "../../../Components/Title/Title.component";
import Layout from "../../../Views/Admin/Layout";
import { useNavigate } from 'react-router-dom';
import {  useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import LoadingWrapper from '../../../Views/Admin/Loading.wrapper';
import Input from '../../../Components/Input/Input.component';
import "react-datepicker/dist/react-datepicker.css";
import { InputContainer } from '../../../Components/Input/Input.styles';
import { useDropzone } from 'react-dropzone';
import { FormikValues, useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { StyledButton } from '../../../Components/Button/Button.styles';
import countryList from 'react-select-country-list';
import Select from "react-select";
import { toastOptions } from '../../../utils/helpers/toast.options';

const residentsCreateValidation = Yup.object({
    name: Yup.string().required('Resident name is required'),
    bio: Yup.string().required('BIO is required'),
    country: Yup.string().required('Country date is required'),
    photo_url: Yup.mixed().required('Cover photo is required'),

    soundcloud_link: Yup.string().optional(),
    ra_link: Yup.string().optional(),
    instagram_link: Yup.string().optional(),
});
        
const ResidentsCreate: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate()
    const [country, setCountry] = useState<any>(null);
    const options: any = useMemo(() => countryList().getData(), [])
    
    const [image, setImage] = useState<any>(null)

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            "image/*": [".png", '.jpg'],
        },
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const file: any = acceptedFiles[0];
            formik.setFieldValue('photo_url', file);

            console.log(URL.createObjectURL(file));
            setImage(URL.createObjectURL(file));
        }
    });

    const onSubmit = async (values: any) => {
        const valuesCopy = {...values};

        const formData = new FormData();
        for (const [key, value] of Object.entries(valuesCopy)) {
            formData.append(key, value as Blob);
        }

        setIsLoading(true);
        
        try {
            await axios.post(`residents`, formData)
                .then(() => {
                    toast.success(`Resident added 🦄`, toastOptions)
                    navigate('/admin/residents?action=create')
                });
        } catch (err) {
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
            name: '',
            bio: '',
            country: '',

            soundcloud_link: '',
            ra_link: '',
            instagram_link: ''
        },
        validationSchema: residentsCreateValidation,
        onSubmit,
    });
    
    return (
        <Layout>
            <LoadingWrapper loading={isLoading}>
                <div className="membersProfile">
                    <div className="membersProfile-info">
                        <Title title={'Add resident'} />
                        <form onSubmit={formik.handleSubmit}>
                            <Input 
                                type='text'
                                placeholder='Type resident name'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Resident name'
                                name='name'
                                error={formik.touched.name && formik.errors.name}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                showRequired
                            />

                            <InputContainer>
                                <label htmlFor='bio'>
                                    BIO
                                    <span className="required-field"> *</span>
                                </label>

                                <textarea 
                                    placeholder='Write resident bio'
                                    className='letter-popup__textarea letter-popup__textarea--simple'
                                    name='bio'
                                    id="bio"
                                    onChange={formik.handleChange}
                                />

                                {formik.touched.bio && formik.errors.bio && <div className='input-error'>{formik.errors.bio}</div>}
                            </InputContainer>

                            <InputContainer>
                                <label htmlFor='country'>
                                    Country
                                    <span className="required-field"> *</span>
                                </label>
                                <Select 
                                    options={options} 
                                    defaultValue={formik.values.country}
                                    onChange={(value) => {
                                        setCountry(value)
                                        formik.setFieldValue('country', value.label);
                                    }}
                                    value={country}
                                    id="country"
                                    name="country"
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            backgroundColor: 'transparent',
                                            height: '64px',
                                            borderColor: '#272727',
                                            borderRadius: '6px',
                                            color: '#FFF'
                                        }),
                                        singleValue: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#FFF'
                                        }),
                                        input: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#FFF'
                                        }),
                                    }}
                                    className='country-selector'
                                />
                                {formik.touched.country && formik.errors.country && <div className='input-error'>{formik.errors.country}</div>}
                            </InputContainer>
                            
                            <InputContainer>
                                <label htmlFor='photo'>
                                    Photo
                                    <span className="required-field"> *</span>
                                </label>
                                
                                <div className="dropPresent dropPresent--event" {...getRootProps()}>
                                    <input id="photo" {...getInputProps()} />
                                    {
                                        isDragActive ?
                                        <p>Drop the file here ...</p> :
                                        <p>Drag 'n' drop some file here, or click to select files</p>
                                    }
                                    {
                                        image &&  <img src={image} alt="preview" className="dropPresent__image dropPresent__image--event" />
                                    }
                                </div>
                                {formik.errors.photo_url && <div className='input-error'>{formik.errors.photo_url}</div>}
                            </InputContainer>
                            
                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Soundcloud link'
                                name='soundcloud_link'
                                value={formik.values.soundcloud_link}
                                onChange={formik.handleChange}
                                error={formik.touched.soundcloud_link && formik.errors.soundcloud_link}
                            />
                            
                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='RA Username'
                                name='ra_link'
                                value={formik.values.ra_link}
                                onChange={formik.handleChange}
                                error={formik.touched.ra_link && formik.errors.ra_link}
                            />

                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Instagram link'
                                name='instagram_link'
                                value={formik.values.instagram_link}
                                onChange={formik.handleChange}
                                error={formik.touched.instagram_link && formik.errors.instagram_link}
                            />
                            {error && <div className='general-error'>{error}</div>}

                            <StyledButton type={'submit'} variant='red' className='sm' disabled={!(formik.isValid && formik.dirty)}>add resident</StyledButton>

                            <br />
                            <br />
                        </form>
                    </div>
                </div>
            </LoadingWrapper>
        </Layout>
    )
}

export default ResidentsCreate;