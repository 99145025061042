import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import styledComponent from "styled-components";

const loginStyles = `
  color: #8FFF68;
  border: 1px solid rgba(143, 255, 104, 0.5);
  padding-right: 25px;
  padding-left: 53px;
  background: #000 url(${require("../../assets/images/icon-bracket.svg").default}) no-repeat left 23px center;
  transition: all .3s ease;
  &:hover {
    color: #fff;
    background: #499F2A url(${require("../../assets/images/icon-bracket-white.svg").default}) no-repeat left 25px center;
  }
`;

const resetStyles = `
  color: #8FFF68;
  border: 1px solid rgba(143, 255, 104, 0.5);
  padding-right: 25px;
  padding-left: 55px;
  background: #000 url(${require("../../assets/images/icon-bracket.svg").default}) no-repeat left 23px center;
  transition: all .3s ease;
  &:hover {
    color: #fff;
    background: #499F2A url(${require("../../assets/images/icon-bracket-white.svg").default}) no-repeat left 25px center;
  }
`;

const invitationStyles = `
  color: #FF7347;
  border: 1px solid #FF7347;
  padding-left: 45px;
  position: relative;
  &:after, &:before {
    content: "";
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    border-radius: 50%;
  }
  &:after {
    left: 12px;
    width: 24px;
    height: 24px;
    border: 1px solid #FF7347;
    transition: all .3s ease;
  }
  &:before {
    left: 17px;
    width: 16px;
    height: 16px;
    background: #FF7347;
    transition: all .3s ease;
  }
  transition: all .3s ease;
  &:hover {
    background-color: #FFA183;
    color: #000000;
    &:after {
      border-color: #000000;
    }
    &:before {
      background: #000000;
    }
  }
  &:active {
    background-color: #FF7347;
    color: #000000;
    &:after {
      border-color: #000000;
    }
    &:before {
      background: #000000;
    }
  }
`;

const disabledStyles = `
  color: #777777;
  border: 1px solid #777777;
  padding-left: 45px;
  position: relative;
  background: transparent url(${require("../../assets/images/dots.svg").default}) no-repeat left 14px center;
  transition: border-color .3s ease;
  text-transform: uppercase;
  &:hover {
    border-color: #D4D4D4;
    color: #D4D4D4;
    background: transparent url(${require("../../assets/images/dots-v2.svg").default}) no-repeat left 15px center;
  }
`;

const cancelStyles = `
  color: #CC5353;
  border: 1px solid #CC5353;
  padding-left: 45px;
  position: relative;
  background: transparent url(${require("../../assets/images/cancel-icon.svg").default}) no-repeat left 14px center;
  transition: border-color .3s ease;
`;

const unbanStyles = `
  color: #C23535;
  border: 1px solid rgba(255, 71, 71, 0.5);
  padding-left: 40px;
  position: relative;
  background: #000 url(${require("../../assets/images/close-icon-v2.svg").default}) no-repeat left 12px center;
  transition: border-color .3s ease;
  text-transform: uppercase;
  &:hover {
    border-color: #FF4747;
    color: #FF2D2D;
    background: #000 url(${require("../../assets/images/close.svg").default}) no-repeat left 13px center;
  }
`;

const banStyles = `
  color: #1D1D1D;
  border: 1px solid rgba(255, 71, 71, 0.5);
  padding-left: 40px;
  position: relative;
  text-transform: uppercase;
  background: #FF4747 url(${require("../../assets/images/dot-black.svg").default}) no-repeat left 14px center;
  &:hover {
    
  }
`;

const verifyStyles = `
  color: #31BB02;
  border: 1px solid #31BB02;
  padding-left: 40px;
  position: relative;
  background: #000 url(${require("../../assets/images/status-verified-v2.svg").default}) no-repeat left 11px center;
  transition: border-color .3s ease;
  text-transform: uppercase;
  &:hover {
    color: #8FFF68;
    border-color: #8FFF68;
    background: #000 url(${require("../../assets/images/verify-icon.svg").default}) no-repeat left 11px center;
  }
`;

const redStyles = `
  height: 52px;
  padding-left: 30px;
  position: relative;
  transition: all .3s ease;
  background-color: #FF3131;
  color: #fff;
  border-radius: 6px;
  text-transform: uppercase;
  padding-right: 30px;
  &:hover {
    padding-right: 30px;
    background-color: #FF4747;
  }
  &:active {
    transform: scale(1.05);
  }
  &.sm {
    height: 42px;
    font-size: 16px;
    font-family: 'Main Bold',sans-serif;
    padding: 0 24px;
    border-radius: 39px;
    text-transform: capitalize;
  }
`;

const greenStyles = `
  height: 52px;
  padding-left: 30px;
  position: relative;
  transition: all .3s ease;
  background-color: #00474F;
  color: #fff;
  border-radius: 6px;
  text-transform: uppercase;
  padding-right: 30px;
  &:hover {
    background-color: #046974;
  }
  &:active {
    transform: scale(1.05);
  }
`;

const verifiedStyles = `
  color: #1D1D1D;
  border: 1px solid rgba(65, 207, 16, 1);
  padding-left: 40px;
  position: relative;
  background: #41CF10 url(${require("../../assets/images/checkbox-black-icon.svg").default}) no-repeat left 11px center;
  text-transform: uppercase;
`;

const purpleStyles = `
  color: #fff;
  border: 1px solid #855BE;
  padding-left: 40px;
  position: relative;
  background: #593B9C url(${require("../../assets/images/checkbox-white-icon.svg").default}) no-repeat left 11px center;
`;

const strokedStyles = `
  color: #fff;
  border: 1px solid #fff;
  padding-left: 40px;
  position: relative;
  background: transparent url(${require("../../assets/images/checkbox-white-icon.svg").default}) no-repeat left 11px center;
`;

const greenSubmitStyles = `
  color:#000;
  background:#8FFF68;
  border-radius:6px;
  padding: 14px 30px;
  font-family:EuroExt;
`;

const strokedRedStyles = `
  color: #FF4747;
  padding: 20px;
  border: 1px solid #FF4747;
  position: relative;
  background: transparent;
  &.sm {
    height: 42px;
    font-size: 16px;
    font-family: 'Main Bold',sans-serif;
    padding: 0 24px;
    border-radius: 39px;
    text-transform: capitalize;
  }
  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

export interface IButton {
    children: ReactNode;
    variant?: "login" | "reset" | "invitation" | 'disabled' | 'unban' | 'verify' | 'verified' | 'ban' | 'purple' | 'stroked' | 'red' | 'green' | 'cancel' | 'stroked-red' | 'green-submit';
    to?: string;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
}

const Styles = `
  outline: none;
  padding: 0;
  border: none;
  height: 40px;
  border-radius: 40px;
  cursor: pointer;
  text-transform: capitalize;
  padding-right: 12px;
  font-family: 'Main Bold', sans-serif;
  display: flex;
  align-items: center;
`

const disabledButton = `
  cursor:not-allowed;
  opacity:0.5;
`
export const StyledButton = styledComponent.button<IButton>`
  ${Styles}
  ${({ variant }) => variant === "login" && loginStyles}
  ${({ variant }) => variant === "reset" && resetStyles}
  ${({ variant }) => variant === "invitation" && invitationStyles}
  ${({ variant }) => variant === "disabled" && disabledStyles}
  ${({ variant }) => variant === "unban" && unbanStyles}
  ${({ variant }) => variant === "verify" && verifyStyles}
  ${({ variant }) => variant === "verified" && verifiedStyles}
  ${({ variant }) => variant === "ban" && banStyles}
  ${({ variant }) => variant === "purple" && purpleStyles}
  ${({ variant }) => variant === "stroked" && strokedStyles}
  ${({ variant }) => variant === "red" && redStyles}
  ${({ variant }) => variant === "green" && greenStyles}
  ${({ variant }) => variant === "cancel" && cancelStyles}
  ${({ variant }) => variant === "stroked-red" && strokedRedStyles}
  ${({ variant }) => variant === "green-submit" && greenSubmitStyles}
  ${({ disabled }) => disabled && disabledButton}
`;

export const StyledLink = styledComponent.a<IButton>`
  ${Styles}
  ${({ variant }) => variant === "login" && loginStyles}
  ${({ variant }) => variant === "reset" && resetStyles}
  ${({ variant }) => variant === "invitation" && invitationStyles}
  ${({ variant }) => variant === "disabled" && disabledStyles}
  ${({ variant }) => variant === "unban" && unbanStyles}
  ${({ variant }) => variant === "red" && redStyles}
  ${({ disabled }) => disabled && disabledButton}
`;

export const StyledNavLink = styledComponent(NavLink)<IButton>`
  ${Styles}
  ${({ variant }) => variant === "login" && loginStyles}
  ${({ variant }) => variant === "reset" && resetStyles}
  ${({ variant }) => variant === "invitation" && invitationStyles}
  ${({ variant }) => variant === "disabled" && disabledStyles}
  ${({ variant }) => variant === "unban" && unbanStyles}
  ${({ disabled }) => disabled && disabledButton}
`;