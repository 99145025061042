import { StyledButton } from '../Button/Button.styles';
import { NavLink } from 'react-router-dom';

export interface ContentBlockComponentProps {
  id: string;
  key_code: string;
  value: string;
}

const ContentBlockComponent: React.FC<ContentBlockComponentProps> = ({
  key_code,
  id,
  value
}) => {
  return (
    <div className="userInfo">
      <NavLink to={`/admin/content/${key_code}`} className="userInfoContainer">
        <div className="userInfo__container">
          <div className="userInfo__text">
            <div className="userInfo__text-title">Text key</div>
            <div className="userInfo__text-text">{key_code}</div>
          </div>
          <div className="userInfo__text">
            <div className="userInfo__text-title">Value</div>
            <div className="userInfo__text-text" title={value}>{value}</div>
          </div>
        </div>
        <div className="userInfo__actions">
          <StyledButton variant="disabled">
            Details
          </StyledButton>
        </div>
      </NavLink>
    </div>
  );
};

export default ContentBlockComponent;
