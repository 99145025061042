import { useState } from "react";
import { IUser } from "../../utils/interfaces/user.interface";
import LetterPopupForm from "../../Components/LetterPopupForm/LetterPopupForm";

const ProfileInvitesPresentation: React.FC<{ user: IUser }> = ({ user }) => {
    const [openPopupId, setOpenPopupId] = useState<string | null>(null);
  
    return (
      <>
        {user.invites.map((invite: IUser) => (
          <div className="friendList" key={invite.id}>
            <div className="flex flex-jcfs">
              <div className="friendList__text">{invite.full_name}</div>
              <div className="friendList__text friendList__text--bold">
                {invite.email}
              </div>
            </div>
            <div className="flex flex-aic flex-jcfs">
              {invite.invite.need_letter && (
                <div>
                  <LetterPopupForm
                    request_by={user}
                    user={invite}
                    invite={invite.invite}
                    isopen={openPopupId === invite.id}
                    onClose={() => setOpenPopupId(null)}
                  />
                  <div
                    onClick={() => setOpenPopupId(invite.id)}
                    className="friendList__status memberInfo__image-text-icon--letter"
                  >
                    {invite.invite.letter ? "view letter" : "add letter"}
                  </div>
                </div>
              )}
              <div
                className={`friendList__status memberInfo__image-text-icon--${invite.status} memberInfo__image-text--${invite.status}`}
              >
                {invite.status}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };
  
  export default ProfileInvitesPresentation;