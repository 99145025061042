import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Sidebar.scss';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import clsx from 'clsx';

const SidebarView:FC<{open:boolean}> = ({open}) => {
  const { pathname } = useLocation();

  return (
    <div className={clsx("sidebar", open && "sidebar--open")}>
      <NavLink to="/" className="sidebar__logo" />
      <div className="sidebar__menu">
        <div className="sidebar__menu__container">
          <div className="sidebar__menu-title">ADMIN FEATURES</div>
          <PermissionGuard role={PermissionsEnum.DASHBOARD_PAGE_VIEW}>
            <NavLink
              to="/admin"
              className={`sidebar__menu-item sidebar__menu-item--dashboard ${pathname === '/admin' ? 'active-link' : ''}`}
            >
              Dashboard
            </NavLink>
          </PermissionGuard>

          <PermissionGuard role={PermissionsEnum.MEMBERS_PAGE_VIEW}>
            <NavLink
              to="/admin/members?status=ALL"
              className={`sidebar__menu-item  sidebar__menu-item--members ${
                pathname.includes('/admin/members') ? 'active-link' : ''
              }`}
            >
              Members
            </NavLink>
          </PermissionGuard>

          <PermissionGuard role={PermissionsEnum.INVITE_MEMBERS_PAGE_VIEW}>
            <NavLink
              to="/admin/invites?status=INVITED"
              className={`sidebar__menu-item  sidebar__menu-item--invites ${pathname === '/admin/invites' ? 'active-link' : ''}`}
            >
              Invite members
            </NavLink>
          </PermissionGuard>

          <PermissionGuard role={PermissionsEnum.EVENTS_PAGE_VIEW}>
            <NavLink
              to="/admin/events?status=ALL"
              className={`sidebar__menu-item  sidebar__menu-item--events ${pathname.startsWith('/admin/events') ? 'active-link' : ''}`}
            >
              Events
            </NavLink>
          </PermissionGuard>

          <PermissionGuard role={PermissionsEnum.SHOW_ALL_ROLES}>
            <NavLink
              to="/admin/user-roles"
              className={`sidebar__menu-item  sidebar__menu-item--roles ${pathname.startsWith('/admin/user-roles') ? 'active-link' : ''}`}
            >
              Member Roles
            </NavLink>
          </PermissionGuard>

          <PermissionGuard role={PermissionsEnum.SHOW_LOST_AND_FOUND}>
            <NavLink
              to="/admin/lost-and-found?status=ALL"
              className={`sidebar__menu-item  sidebar__menu-item--lostandfound ${pathname.startsWith('/admin/lost-and-found') ? 'active-link' : ''}`}
            >
              Lost & Found
            </NavLink>
          </PermissionGuard>

          <PermissionGuard role={PermissionsEnum.RESIDENTS_PAGE_VIEW}>
            <NavLink
              to="/admin/residents"
              className={`sidebar__menu-item  sidebar__menu-item--residents ${
                pathname.startsWith('/admin/residents') ? 'active-link' : ''
              }`}
            >
              Residents
            </NavLink>
          </PermissionGuard>
          
          <PermissionGuard role={PermissionsEnum.CONTENT_PAGE_VIEW}>
            <NavLink
              to="/admin/content"
              className={`sidebar__menu-item   sidebar__menu-item--content ${
                pathname === '/admin/content' ? 'active-link' : ''
              }`}
            >
              Content
            </NavLink>
          </PermissionGuard>
        </div>
      </div>
    </div>
  );
};

export default SidebarView;
