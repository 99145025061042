import './CompleteProfile.page.scss';

import { useAuthUser } from "react-auth-kit";
import UserMiniInfo from "../../Components/UserMiniInfo/UserMiniInfo.component";
import RequestVerificationForm from "../../Views/RequestVerificationForm/RequestVerificationForm";

const CompleteRegistration: React.FC = () => {
    const auth = useAuthUser();
    
    return (
        <div className=" reqVerificationPage">
            <RequestVerificationForm />
            <UserMiniInfo 
                full_name={auth()?.full_name}
            />
        </div>
    )
}

export default CompleteRegistration;