import { useEffect, useState } from "react";

export const useBreakpoint = () => {
    const [breakpoint, setBreakPoint] = useState(0);

    useEffect(() => {
        window.addEventListener('resize', getBreakpoint);
        getBreakpoint()
        return () => window.removeEventListener('resize', getBreakpoint);
    }, [])
    
    const getBreakpoint = () => {
        setBreakPoint(window.innerWidth);
    }

    return {
        width:breakpoint
    }
}