import { FC, useEffect, useState } from 'react';
import './StateSwitch.component.scss';

export type TStateSwitch = {
    status: "lost" | "found" | "not_found",
    onClick: (value:"lost" | "found" | "not_found") => void,
}

export const StateSwitch:FC<TStateSwitch> = ({status,onClick}) => {
    const [value, setValue] = useState(status);

    useEffect(()=>{
        setValue(status);
    },[status])

    const handleClick = (inputValue:"lost" | "found" | "not_found") => {
        onClick(inputValue);
    }
    return(
        <div className="state-switch">
            <div className={`state-switch__item text--bold found ${value === 'found' ? "active" : ""}`} onClick={() => handleClick("found")}>Found</div>
            <div className={`state-switch__item text--bold not-found ${value === 'not_found' ? "active" : ""}`} onClick={() => handleClick("not_found")}>Not Found</div>
            <div className={`state-switch__item text--bold lost ${value === 'lost' ? "active" : ""}`} onClick={() => handleClick("lost")}>Lost</div>
        </div>
    )
}