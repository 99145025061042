import { NavLink } from 'react-router-dom';
import { IItemBlock } from './ItemBlock.interface';
import { useState } from 'react';
import axios from 'axios';
import { PermissionGuard } from '../PermissionGuard/PermissionGuard.component';
import { PermissionsEnum } from '../../utils/interfaces/permissions.enum';
import { toast } from 'react-toastify';
import { toastOptions } from '../../utils/helpers/toast.options';
import { StateSwitch } from '../StateSwitch/StateSwitch.component';
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';

const ItemBlock: React.FC<IItemBlock> = ({ 
  id,
  fullName,
  phone,
  email,
  status,
  canUpdate,
}) => {
    const [ checkbox, setCheckbox ] = useState(status);
    const [ beforeValue, setBeforeValue ] = useState(status);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const onPopupAction = async (action: boolean) => {
        if(action) {
            setIsPopupOpen(false);
            await axios.patch(`lostandfound/${id}`,{status:beforeValue})
                .then(() => {
                    toast.success(`Status changed 🦄`, toastOptions)
                    setCheckbox(beforeValue);
                })
                .catch((e)=>{
                    console.log(e);
                })
        }
        else {
            setBeforeValue(checkbox)
            setIsPopupOpen(false)
        }
    }

    return (
        <div className="userInfo">
            <div className="userInfoContainer">
                <NavLink className="userInfo__container" to={`/admin/lost-and-found/${id}`}>
                <div className="userInfo__text">
                    <div className="userInfo__text-title">Full name</div>
                    <div className="userInfo__text-text">{fullName}</div>
                </div>
                <div className="userInfo__text">
                    <div className="userInfo__text-title">Phone</div>
                    <div className="userInfo__text-text">{phone}</div>
                </div>
                <div className="userInfo__text">
                    <div className="userInfo__text-title">Email</div>
                    <div className="userInfo__text-text">{email}</div>
                </div>
                </NavLink>
                <PermissionGuard role={PermissionsEnum.UPDATE_LOST_AND_FOUND_STATUS}>
                    <div className="userInfo__actions">
                        <StateSwitch 
                            status={checkbox} 
                            onClick={(value)=> {
                                if(value === checkbox) return
                                setBeforeValue(value)
                                setIsPopupOpen(true)
                            }}
                        />
                    </div>
                </PermissionGuard>
            </div>
            <ConfirmPopup 
              isopen={isPopupOpen} 
              onAction={onPopupAction}
              text={'Are you sure you want to change the status?'} 
              label='Status change'
            />
        </div>
    );
};

export default ItemBlock;
