import { useEffect, useState } from "react";
import StatisticsComponent from "../../../Components/Statistics/Statistics.component"
import LoadingWrapper from "../Loading.wrapper";
import axios from 'axios';
import { AxiosResponse } from 'axios';
import { IStatistics } from "../../../utils/interfaces/statistics.interface";
import Title from "../../../Components/Title/Title.component";
import {Collapse} from 'react-collapse';
import CollapseComponent from "../../../Components/Collapse/Collapse.component";

const OverviewView: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [statistics, setStatistics] = useState<IStatistics>();
    const [isOpen, setIsOpen] = useState<boolean>(true);
    
    const getStatistics = async (): Promise<void> => {
        await axios.get(`users/statistics`)
            .then((res: AxiosResponse) => {
                const { data } = res;
                
                setStatistics(data);
                setIsLoading(false);
            })
    };

    useEffect(() => {
        getStatistics();
    }, []);
    
    return (
        <div className="overview">
            <div className="flex flex-jcsb">
                <Title title="Overview" />

                <CollapseComponent isOpen={isOpen} onToggle={() => setIsOpen(!isOpen)} />
            </div>
            <Collapse isOpened={isOpen}>
                <LoadingWrapper loading={isLoading}>
                    <StatisticsComponent 
                        verified={statistics?.verified || 0}
                        pending={statistics?.pending || 0}
                        requested={statistics?.unverified || 0}
                        banned={statistics?.banned || 0}
                        all={statistics?.all || 0}
                    />
                </LoadingWrapper>
            </Collapse>
        </div>
    )
}

export default OverviewView;