import { motion } from 'framer-motion';

type TMenuIcon = {
    isOpen:boolean;
}

export const MenuIcon = ({isOpen}:TMenuIcon) => {
    const pathVariants = {
        open:{
            opacity: 1,
        },
        hidden:{
            opacity: 0,
        }
    }
    return (
        <motion.svg
          width="33"
          height="27"
        >
            <motion.path 
                d="M27 1.81753L25.2169 0L13.5 11.9438L1.78315 0L0 1.81753L11.7169 13.7614L0 25.7053L1.78315 27.5228L13.5 15.579L25.2169 27.5228L27 25.7053L15.2831 13.7614L27 1.81753Z" 
                fill="white"
                initial="hidden"
                animate={!isOpen? 'hidden' : 'open'}
                variants={pathVariants}
            />
            <motion.path 
                d="M24.0681 12.8496H2.33258C1.62426 12.8496 1.05005 13.4238 1.05005 14.1321V14.2671C1.05005 14.9755 1.62426 15.5497 2.33258 15.5497H24.0681C24.7764 15.5497 25.3506 14.9755 25.3506 14.2671V14.1321C25.3506 13.4238 24.7764 12.8496 24.0681 12.8496Z" 
                fill="#D7D7D7"
                initial="open"
                animate={isOpen? 'hidden' : 'open'}
                variants={pathVariants}
            />
            <motion.path 
                d="M24.0681 19.5996H2.33258C1.62426 19.5996 1.05005 20.1738 1.05005 20.8821V21.0171C1.05005 21.7255 1.62426 22.2997 2.33258 22.2997H24.0681C24.7764 22.2997 25.3506 21.7255 25.3506 21.0171V20.8821C25.3506 20.1738 24.7764 19.5996 24.0681 19.5996Z" 
                fill="#D7D7D7"
                initial="open"
                animate={isOpen? 'hidden' : 'open'}
                variants={pathVariants}
            />
            <motion.path 
                d="M24.0681 6.09961H2.33258C1.62426 6.09961 1.05005 6.67382 1.05005 7.38214V7.51714C1.05005 8.2255 1.62426 8.7997 2.33258 8.7997H24.0681C24.7764 8.7997 25.3506 8.2255 25.3506 7.51714V7.38214C25.3506 6.67382 24.7764 6.09961 24.0681 6.09961Z" 
                fill="#D7D7D7"
                initial="open"
                variants={pathVariants}
                animate={isOpen? 'hidden' : 'open'}
            />
        </motion.svg>
    )
}
