import { FC } from "react"
import { Checkbox } from "../Checkbox/Checkbox.component"
import './RoleModule.scss';
import { TRolesTree } from "../../constants/permissions.constant";
import { FormikHelpers, FormikValues } from "formik";

interface IRoleModule {
    title:string,
    permissions:string,
    roles:TRolesTree[],
    setField:FormikHelpers<FormikValues>["setFieldValue"],
    formRoles:FormikValues,
    handleChange: (e: React.ChangeEvent) => void
    
}

export const RoleModule:FC<IRoleModule> = ({
    title,
    permissions,
    roles,
    setField,
    handleChange,
    formRoles
}) => {
    const handleBulkChange = (e:Event) => {
        const target = e.target as HTMLInputElement;
        const isChecked = target.checked;
        const perms = target.value.trim().split(",");
        const roles = JSON.parse(JSON.stringify(formRoles));
        
        if(isChecked){
            const uniqueRoles = Array.from(new Set([...roles, ...perms]));
            setField('roles', uniqueRoles);
        }else{
            const filteredRoles = roles.filter((role: string) => !perms.includes(role));
            setField('roles',filteredRoles)
        }
    }
    
    return(
        <div className="role-module__module">
            <div className="role-module__module-head bold">
                {title}
                <Checkbox 
                    value={permissions} 
                    onChange={handleBulkChange} 
                    checked={
                        permissions?.split(",")
                            .every((permission:string) => 
                                formRoles.includes(permission))
                    }/>
            </div>
            <div className="role-module__body">
                {
                    roles.map((role)=>
                        <div className="role-module__role" key={role.id}>
                            {role.title}
                            <Checkbox  
                                onChange={handleChange}
                                value={role.id}  
                                name="roles"
                                checked={formRoles?.includes(role.id)}/>
                        </div>
                    )
                }
            </div>
        </div>
    )
}