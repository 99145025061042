import './Footer.scss';
// import {ReactComponent as FBIcon}  from '../../assets/images/fb-icon.svg';
import {ReactComponent as InstaIcon}  from '../../assets/images/insta-icon.svg';
// import {ReactComponent as TwitterIcon}  from '../../assets/images/twitter-icon.svg';
import {ReactComponent as RaIcon}  from '../../assets/images/ra-icon.svg';
import {ReactComponent as SoundCloudIcon}  from '../../assets/images/soundcloud-icon.svg';
import { useContext, useState } from 'react';
import PopupWrapper, { StyledPopupHeader } from '../Admin/Popup.wrapper';
import { LostAndFoundPopup } from '../../Components/LostAndFoundPopup/LostAndFoundPopup.component';
import { LangsContext } from '../../context/LangsContext';

const Footer = () => {
    const [lostAndFound, setLostAndFound] = useState(false);
    // const content = useContext(RulesContext);

    const content = useContext(LangsContext);

    return (
        <footer className='footer'>
            <div className="footer__col">
                52-19 Flushing Ave. 
                <br/><br/>
                Maspeth, NY 11378  
            </div>
            <div className="footer__col footer__col--center">
                {/* <a target='_blank' rel="noreferrer" href="https://www.facebook.com/basementnewyork/">
                    <FBIcon />
                </a> */}
                <a target='_blank' rel="noreferrer" href="https://www.instagram.com/basementnewyork/">
                    <InstaIcon />
                </a>
                {/* <a target='_blank' rel="noreferrer" href="https://twitter.com/basementnewyork?lang=en">
                    <TwitterIcon />
                </a> */}
                <a target='_blank' rel="noreferrer" href="https://ra.co/clubs/165976">
                    <RaIcon />
                </a>
                <a target='_blank' rel="noreferrer" href="https://soundcloud.com/basementnewyork">
                    <SoundCloudIcon />
                </a>
            </div>

            <PopupWrapper isopen={lostAndFound}>
                <StyledPopupHeader>
                    <div className="popup-title">
                        {content?.LOST_AND_FOUND || 'Lost & Found'}
                    </div>
                    <div className="close" onClick={()=> setLostAndFound(false)}></div>
                </StyledPopupHeader>
                {/* <div className="text text--readable text--white text--center">
                    {content && content['lost and found email']}
                </div> */}
                <LostAndFoundPopup />

            </PopupWrapper>
            
            <div className="footer__col footer__col--right cur-p" onClick={() => setLostAndFound(true)}>
                {content?.LOST_AND_FOUND || 'Lost & Found'}
            </div>
        </footer>
    )
}

export default Footer