import './ResidentDetail.scss';

import Title from "../../../Components/Title/Title.component";
import Layout from "../../../Views/Admin/Layout";
import { useNavigate, useParams } from 'react-router-dom';
import {  useEffect, useMemo, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import LoadingWrapper from '../../../Views/Admin/Loading.wrapper';
import Input from '../../../Components/Input/Input.component';
import "react-datepicker/dist/react-datepicker.css";
import { InputContainer } from '../../../Components/Input/Input.styles';
import { useDropzone } from 'react-dropzone';
import { FormikValues, useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { StyledButton } from '../../../Components/Button/Button.styles';
import countryList from 'react-select-country-list';
import Select from "react-select";
import { IResident } from '../../../utils/interfaces/residents.interface';
import { Button } from '../../../Components/Button/Button.component';
import ConfirmPopup from '../../../Components/ConfirmPopup/ConfirmPopup';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';
import { toastOptions } from '../../../utils/helpers/toast.options';

const residentsCreateValidation = Yup.object({
    name: Yup.string().required('Resident name is required'),
    bio: Yup.string().required('BIO is required'),
    country: Yup.string().required('Country date is required'),

    soundcloud_link: Yup.string().optional(),
    ra_link: Yup.string().optional(),
    instagram_link: Yup.string().optional(),
});
        
const ResidentUpdate: React.FC = () => {
    const {resident_id} = useParams<{resident_id: string}>();
    
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [country, setCountry] = useState<any>(null);
    const options: any = useMemo(() => countryList().getData(), [])
    const [resident, setResident] = useState<IResident | null>(null);
    const navigate = useNavigate()
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const canUpdateResident = usePermissionGuard(PermissionsEnum.UPDATE_RESIDENTS);

    const [image, setImage] = useState<any>(null)

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            "image/*": [".png", '.jpg'],
        },
        maxFiles: 1,
        disabled: canUpdateResident ? false : true,
        onDrop: (acceptedFiles) => {
            const file: any = acceptedFiles[0];
            formik.setFieldValue('photo_url', file);

            console.log(URL.createObjectURL(file));
            
            setImage(URL.createObjectURL(file));
        }
    });

    const onSubmit = async (values: any) => {
        const valuesCopy = {...values};

        const formData = new FormData();
        for (const [key, value] of Object.entries(valuesCopy)) {
            formData.append(key, value as Blob);
        }

        setIsLoading(true);
        
        try {
            await axios.patch(`residents/${resident_id}`, formData)
                .then(() => {
                    toast.success(`Resident updated 🦄`, toastOptions)
                });
        } catch (err) {
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const getResidentInfo = async (): Promise<void> => {
        if(resident) return;
        
        await axios.get(`/residents/${resident_id}`)
            .then((res: AxiosResponse) => {
                const {data} = res;

                formik.setFieldValue('name', data.name);
                formik.setFieldValue('bio', data.bio);
                formik.setFieldValue('ra_link', data.ra_link);

                formik.setFieldValue('soundcloud_link', data.soundcloud_link);
                formik.setFieldValue('instagram_link', data.instagram_link);
                formik.setFieldValue('country', data.country);
                
                setCountry({label: data.country, value: data.country})
                setImage(`${process.env.REACT_APP_HOST}/public/residents/${data.photo_url}`);
                setResident(data);
                setIsLoading(false)
            })
    }
    
    const formik: FormikValues = useFormik({
        initialValues: {
            name: '',
            bio: '',
            country: '',
 
            soundcloud_link: '',
            ra_link: '',
            instagram_link: ''
        },
        validationSchema: residentsCreateValidation,
        onSubmit,
    });

    const onPopupAction = async (trueOrFalse: boolean): Promise<void> => {
        setIsPopupOpen(false)
        
        if(trueOrFalse) {
            await axios.delete(`residents/${resident_id}`)
                .then(() => {
                    navigate('/admin/residents?action=delete')
                })
        }
    }
    
    useEffect(() => {
        getResidentInfo();
        // eslint-disable-next-line
    }, [resident_id])
    
    return (
        <Layout>
            <LoadingWrapper loading={isLoading}>
                <div className="membersProfile">
                    <div className="membersProfile-info">
                        <Title title={'Add resident'} />
                        <form onSubmit={formik.handleSubmit}>
                            <Input 
                                type='text'
                                placeholder='Type event name'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Resident name'
                                name='name'
                                disabled={canUpdateResident ? false : true}
                                error={formik.touched.name && formik.errors.name}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                showRequired
                            />

                            <InputContainer>
                                <label htmlFor='bio'>
                                    BIO
                                    <span className="required-field"> *</span>
                                </label>

                                <textarea 
                                    placeholder='Write resident bio'
                                    className='letter-popup__textarea letter-popup__textarea--simple'
                                    name='bio'
                                    id="bio"
                                    disabled={canUpdateResident ? false : true}
                                    onChange={formik.handleChange}
                                    value={formik.values.bio}
                                />

                                {formik.errors.bio && <div className='error'>{formik.errors.bio}</div>}
                            </InputContainer>

                            <InputContainer>
                                <label htmlFor='country'>
                                    Country
                                    <span className="required-field"> *</span>
                                </label>
                                <Select 
                                    options={options} 
                                    defaultValue={formik.values.country}
                                    onChange={(value) => {
                                        setCountry(value)
                                        formik.setFieldValue('country', value.label);
                                    }}
                                    value={country}
                                    id="country"
                                    isDisabled={canUpdateResident ? false : true}
                                    name="country"
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            backgroundColor: 'transparent',
                                            height: '64px',
                                            borderColor: '#272727',
                                            borderRadius: '6px',
                                            color: '#FFF'
                                        }),
                                        singleValue: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#FFF'
                                        }),
                                        input: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#FFF'
                                        }),
                                    }}
                                    className='country-selector'
                                />
                                {formik.touched.country && formik.errors.country && <div className='input-error'>{formik.errors.country}</div>}
                            </InputContainer>
                            
                            <InputContainer>
                                <label htmlFor='photo'>
                                    Photo
                                    <span className="required-field"> *</span>
                                </label>
                                
                                <div className="dropPresent dropPresent--event" {...getRootProps()}>
                                    <input id="photo" {...getInputProps()} />
                                    {
                                        isDragActive ?
                                        <p>Drop the file here ...</p> :
                                        <p>Drag 'n' drop some file here, or click to select files</p>
                                    }
                                    {
                                        image &&  <img src={image} alt="preview" className="dropPresent__image dropPresent__image--event" />
                                    }
                                </div>
                            </InputContainer>
                            
                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Soundcloud link'
                                name='soundcloud_link'
                                disabled={canUpdateResident ? false : true}
                                value={formik.values.soundcloud_link}
                                onChange={formik.handleChange}
                                error={formik.touched.soundcloud_link && formik.errors.soundcloud_link}
                            />
                            
                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='RA Username'
                                name='ra_link'
                                disabled={canUpdateResident ? false : true}
                                value={formik.values.ra_link}
                                onChange={formik.handleChange}
                                error={formik.touched.ra_link && formik.errors.ra_link}
                            />

                            <Input 
                                type='text'
                                placeholder='Paste the link here'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Instagram link'
                                name='instagram_link'
                                disabled={canUpdateResident ? false : true}
                                value={formik.values.instagram_link}
                                onChange={formik.handleChange}
                                error={formik.touched.instagram_link && formik.errors.instagram_link}
                            />

                            {error && <div className='input-error'>{error}</div>}

                            <ConfirmPopup
                                label={'Are you sure you want to delete the resident?'}
                                text={'Resident will be deleted permanently.'}
                                onAction={onPopupAction}
                                isopen={isPopupOpen}
                            />
                            
                            <div className='flex flex-jcsb'>
                                <PermissionGuard role={PermissionsEnum.DELETE_RESIDENTS}>
                                    <Button type={'button'} onClick={() => setIsPopupOpen(true)} variant='stroked-red' className='sm'>delete resident</Button>
                                </PermissionGuard>
                                <span></span>
                                <PermissionGuard role={PermissionsEnum.UPDATE_RESIDENTS}>
                                    <StyledButton type={'submit'} variant='red' className='sm'>update resident</StyledButton>
                                </PermissionGuard>
                            </div>

                            <br />
                            <br />
                        </form>
                    </div>
                </div>
            </LoadingWrapper>
        </Layout>
    )
}

export default ResidentUpdate;