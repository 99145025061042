import { IButton, StyledButton, StyledLink } from "./Button.styles";

const Button: React.FC<IButton & { type?: "button" | "submit" | "reset" }> = ({ children, variant, disabled, onClick, type , className}) => {
    return <StyledButton variant={variant} className={className} type={type} disabled={disabled} onClick={onClick}>{children}</StyledButton>;
};

const Link: React.FC<IButton> = ({ children, variant, to, disabled, ...rest }) => {
    return <StyledLink variant={variant} to={to} disabled={disabled} {...rest}>{children}</StyledLink>;
};

export {Button, Link};