import './InviteMemberPopup.scss';
import PopupWrapper, {StyledPopupHeader} from '../../Views/Admin/Popup.wrapper';
import LoadingWrapper from '../../Views/Admin/Loading.wrapper';
import * as Yup from 'yup';
import { useState } from 'react';
import axios from 'axios';
import { FormikValues, useFormik } from 'formik';
import Input from '../Input/Input.component';
import { StyledButton } from '../Button/Button.styles';

const loginValidationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
});

const InviteMemberPopup: React.FC<{
  isopen: boolean;
  onAction: (action: boolean) => void;
  onAdd: () => void;
  label: string;
}> = ({ isopen, onAction, label, onAdd }) => {
  const [loading, setLoading] = useState<boolean>(false)
  
  const onSubmit = async (values: any) => {
      setLoading(true)
      
      axios.post('invites', {
        email: values.email
      })
      .then(() => {
          onAdd()
      })
      .catch((err) => {
          console.log(err)
          formik.setErrors({email: err.response.data.message})
      })
      setLoading(false)
    }
  
  const formik: FormikValues = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit,
  });

  return (
    <PopupWrapper isopen={isopen}>
      <StyledPopupHeader>
        <div className="popup-title">{label}</div>
        <div className="close" onClick={() => onAction(false)}></div>
      </StyledPopupHeader>
      <div className="statusChange">

        <LoadingWrapper loading={loading}>
            <form onSubmit={formik.handleSubmit}>
                <Input 
                    type='text'
                    placeholder='Email: example@gmail.com'
                    className='loginForm__input loginForm__input--noRounded'
                    label='Email'
                    name='email'
                    autoComplete="username"
                    error={formik.touched.email && formik.errors.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                />
                
                <div className='loginForm__actions flex-jcfe'>
                    <StyledButton type={'submit'} variant='red' className="sm">Send invitation</StyledButton>
                </div>
            </form>
        </LoadingWrapper>
        
      </div>
    </PopupWrapper>
  );
};

export default InviteMemberPopup;
