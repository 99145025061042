import { useContext } from "react";
import PopupWrapper, { StyledPopupHeader } from "../../Views/Admin/Popup.wrapper"
import { LangsContext } from "../../context/LangsContext";

interface ITermsAndCondition {
    isOpen: boolean,
    onClose: () => void
}

const TermsAndCondition = ({
    isOpen,
    onClose
}: ITermsAndCondition) => {
    const content = useContext(LangsContext);

    return (
        <PopupWrapper isopen={isOpen}>
            <StyledPopupHeader>
                <div className="popup-title">Terms & Condition</div>
                <div className="close" onClick={onClose}></div>
            </StyledPopupHeader>
            <div className="text text--readable text--white" dangerouslySetInnerHTML={{
                __html: content?['TERMS_AND_CONDITION'] && content['TERMS_AND_CONDITION'].replaceAll('\r','</br>').replaceAll('\n', '<br />') : ''
            }}></div>
        </PopupWrapper>
    )
}

export default TermsAndCondition