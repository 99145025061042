import axios, { AxiosResponse } from "axios"
import { createContext, useEffect, useState } from "react"

export type HouseRulesType = {
    [index:string]: string,
    house_subtitle: string;
    house_title: string;
} | null

type LangsProviderType = {
    children: JSX.Element
}

export const LangsContext = createContext<HouseRulesType | null>(null)

export const LangsProvider = ({children}:LangsProviderType) => {
    const [ rules, setRules ] = useState<HouseRulesType | null>(null)
    const getRules = () => {
        axios.get('/content?type=json')
            .then((res:AxiosResponse) => {
                setRules(res.data);
            })
    }

    useEffect(()=>{
        getRules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return <LangsContext.Provider value={rules}>
        {children}
    </LangsContext.Provider>
}