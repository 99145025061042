import { useState } from "react";
import ConfirmPopup from "../../Components/ConfirmPopup/ConfirmPopup";
import { ISentInvites } from "../../utils/interfaces/user.interface";
import axios from "axios";

const SentInvitesPresentation: React.FC<{sentInvites: ISentInvites[], onDeleteInvite: (id: string) => void }> = ({sentInvites, onDeleteInvite}) => {
    const [openCancelInvitePopup, setOpenCancelInvitePopup] = useState<boolean>(false)
    const [id, setId] = useState<string>('')

    const deleteInvite = async (id: string) => {
        await axios.delete(`invites/${id}`)
            .then(() => {
                onDeleteInvite(id)
            })
    }
    
    const onCancelInvite = (action: boolean) => {
        setOpenCancelInvitePopup(false)
        if(!action) return;

        if(action) {
            deleteInvite(id)
        }
        
        // setSentInvites(sentInvites.filter((invite: ISentInvites) => invite.id !== id))
    }
    
    return (
        <>
            {
                sentInvites.map((sent_invite: ISentInvites) => (
                    <div className='friendList' key={sent_invite.id}>
                        <ConfirmPopup 
                            isopen={openCancelInvitePopup} 
                            onAction={(action: boolean) => onCancelInvite(action)}
                            text='Are you sure you want to cancel invite?' 
                            label="Cancel Invite"
                        />
                        <div className='flex flex-jcfs'>
                            <div className='friendList__text friendList__text--bold'>
                                {sent_invite.email}
                            </div>
                        </div>
                        <div>
                            <div onClick={() => {
                                setOpenCancelInvitePopup(true)
                                setId(sent_invite.id)
                            }} className={`cur-p friendList__status memberInfo__image-text-icon--${sent_invite.status} memberInfo__image-text--${sent_invite.status}`}>
                                {sent_invite.status}
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default SentInvitesPresentation;