import './Residents.scss';

import Title from "../../../Components/Title/Title.component";
import Layout from "../../../Views/Admin/Layout"
import { useEffect, useState } from 'react';
import axios, {AxiosResponse} from 'axios';
import { NavLink, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../../Components/Loader/Loader.component';
import { useDebounce } from 'use-debounce';
import EndMessage from '../../../Components/EndMessage/EndMessage.component';
import { StyledButton } from '../../../Components/Button/Button.styles';
import { toast } from 'react-toastify';
import ResidentsBlockComponent from '../../../Components/ResidentsBlock/ResidentsBlock.component';
import { IResident } from '../../../utils/interfaces/residents.interface';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { toastOptions } from '../../../utils/helpers/toast.options';

const ResidentsPage: React.FC = () => {
    const [residents, setResidents] = useState<IResident[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [value] = useDebounce(searchText, 300)
    const canSeePage = usePermissionGuard(PermissionsEnum.RESIDENTS_PAGE_VIEW)
    
    const getResidents = async (action?: 'next' | null) => {
        let url: string = `residents?limit=10&page=${page}`;

        value && (url += `&search=${value}`)
        
        await axios.get(url)
            .then((res: AxiosResponse) => {
                const { data } = res;
                
                data.length < 10 && setHasMore(false)
                action === 'next' ? setResidents([...residents, ...data]) : setResidents(data);

                setPage(page + 1)
            })
    }

    const searching = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setPage(1)
        setSearchText(e.target.value)
    };

    useEffect(() => {
        if(!canSeePage) return;
        getResidents();

        if(searchParams.get('action') === 'delete') {
            toast.error(`Resident deleted 🥹`, toastOptions)

            setSearchParams({action: ''})
        }

        if(searchParams.get('action') === 'create') {
            toast.success(`Resident added 🕺`, toastOptions)

            setSearchParams({action: ''})
        }
        
        // eslint-disable-next-line
    }, [value, searchParams])

    return (
        <Layout>
            {
                canSeePage && <>
                    <Title title={'Residents'}>
                        <PermissionGuard role={PermissionsEnum.ADD_RESIDENTS}>
                            <NavLink to='/admin/residents/create'>
                                <StyledButton variant="red" className="sm">Add resident</StyledButton>
                            </NavLink>
                        </PermissionGuard>
                    </Title>

                    <div className="navigation">
                        <div className='navigation__container'></div>
                        <input type='text' onChange={searching} value={searchText ? searchText : ''} placeholder='Search residents...' className="navigation__search" />
                    </div>

                    {
                            <InfiniteScroll
                                dataLength={residents.length} 
                                next={() => getResidents('next')}
                                hasMore={hasMore}
                                loader={<Loader />}
                                endMessage={<EndMessage />}
                            >
                                {
                                    residents.map((resident: IResident) => (
                                        <ResidentsBlockComponent
                                            key={resident.id}
                                            id={resident.id}
                                            name={resident.name}
                                            image={resident.photo_url}
                                            country={resident.country}
                                        />
                                    ))
                                }
                            </InfiniteScroll>
                    }
                </>
            }
        </Layout>
    )
}

export default ResidentsPage;