import { ChangeEvent, useContext, useMemo, useState } from "react";
import { FormikValues, useFormik } from "formik";
import { useNavigate, NavigateFunction} from 'react-router-dom';
import axios, { AxiosError, AxiosResponse } from "axios";
import * as Yup from "yup";

import {Button} from '../../Components/Button/Button.component';
import Input from '../../Components/Input/Input.component';
import LoadingWrapper from '../Admin/Loading.wrapper';
import countryList from 'react-select-country-list'
import Select from "react-select";
import StyledInput, { InputContainer } from "../../Components/Input/Input.styles";
import {useDropzone} from 'react-dropzone'

import './RequestVerificationForm.scss'
import { useAuthUser, useSignIn } from 'react-auth-kit';
import TooltipComponent from "../../Components/Tooltip/Tooltip.component";
import { statesData } from "../../utils/helpers/states.data";
import PopupWrapper, { StyledPopupHeader } from "../Admin/Popup.wrapper";
import HomeRules from "../../Components/HouseRules/HouseRules";
import { LangsContext } from "../../context/LangsContext";

const registrationValidationSchema = Yup.object({
    passport_or_driver_license: Yup.string().optional(),
    dob: Yup.date().required("Date of birth is required"),
    country: Yup.string().optional(),
    // profile_photo: Yup.mixed().required("Profile photo is required"),
    soc_links: Yup.array().required("Social links are required"),
    ra_link: Yup.string().required("RA Username is required"),
    state: Yup.string().optional(),
    id_number: Yup.string().optional(),
    // terms: Yup.boolean().oneOf([true],"Terms and Conditions are required")
});

const RequestVerificationForm: React.FC = () => {
    const [ rulesOpen, setRulesOpen ] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate() as NavigateFunction;
    const [socialLinks, setSocialLinks] = useState<string[]>(['']);
    const [country, setCountry] = useState<any>(null);
    const [usaState, setUsaState] = useState<any>(null);
    const user = useAuthUser();
    const signIn = useSignIn();
    const [identityType, setIdentityType] = useState<string>('id');

    const content = useContext(LangsContext);

    const validURL = (str: string) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    const onSubmit = async (values: any) => {
        values.identity_type = identityType;
        delete values.terms;

        if(identityType === 'id' && (values.id_number === '' || values.id_number === null || values.state === '' || values.state === null)) {
            return setError('US STATE ID and state are required');
        }

        if(identityType === 'passport' && (values.country === '' || values.country === null || values.passport_or_driver_license === '' || values.passport_or_driver_license === null)) {
            return setError('Passport and country is required');
        }
        
        if(values.soc_links === '' || values.soc_links === null) {
            return setError('Social links are required');
        }

        values.soc_links = values.soc_links.filter((link: string) => link !== '');

        if(values.soc_links.length === 0) {
            return setError('Social links are required');
        }

        if(values.profile_photo === '' || values.profile_photo === null || !values.profile_photo) {
            return setError('Profile photo is required');
        }

        const isValidSocials = values.soc_links.every((link: string) => validURL(link));

        if(!isValidSocials) {
            return setError('Please to enter full url (exp: instagram.com/@username)');
        }
        
        setIsLoading(true);

        const formData = new FormData() as any;

        for (const [key, value] of Object.entries(values)) {
            formData.append(key, value as Blob);
        }

        for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }

        
        try {
            await axios.post("users/complete-registration", formData)
                .then((response: AxiosResponse) => {
                    const { data } = response;
                    
                    signIn({
                        token: data.token,
                        expiresIn: 999999,
                        tokenType: "Bearer",
                        authState: data.user,
                    });
                    
                    if(user()?.invited_by?.role !== 'MEMBER') {
                        navigate('/profile');
                    }
                    else {
                        navigate('/verification-pending');
                    }
                });
        } catch (err) {
            if (err && err instanceof AxiosError) {
                if(err?.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err?.response?.data?.message);
                }
                
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const addAnotherInput = () => {
        setSocialLinks([...socialLinks, '']);
    };
    
    const handleLinkChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        socialLinks[index] = e.target.value;

        setSocialLinks([...socialLinks]);
    
        formik.setFieldValue('soc_links', [...socialLinks]);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
            soc_links: null,
            passport_or_driver_license: "",
            dob: '',
            country: '',
            profile_photo: null,
            state: '',
            id_number: '',
            ra_link: ''
        },
        validationSchema: registrationValidationSchema,
        onSubmit,
    });

    const onSocialLinkRemove = (index: number) => {
        socialLinks.splice(index, 1);
        formik.setFieldValue('soc_links', [...socialLinks]);
        setSocialLinks([...socialLinks]);
    }
      
    const options: any = useMemo(() => countryList().getData(), [])
    const statesOptions: any = statesData;
    const [image, setImage] = useState<any>(null)

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            "image/*": [".png", '.jpg'],
        },
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const file: any = acceptedFiles[0];
            formik.setFieldValue('profile_photo', file);

            console.log(URL.createObjectURL(file));
            
            setImage(URL.createObjectURL(file));
        }
    });
    
    return (
            <div className='requestVerificationForm'>
                <LoadingWrapper loading={isLoading}>
                    <form onSubmit={formik.handleSubmit} className="approvalForm">
                        <div className="uploadAvatar__container">
                                <InputContainer>
                                    <div className="dropPresent" id="passportStylePhoto" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                            <p>{content?.DROP_THE_FILE || 'Drop the file here ...'}</p> :
                                            <p>
                                                {content?.DROP_YOUR_IMAGE || 'Drop your image here'}
                                            </p>
                                        }
                                        {
                                            image &&  <img src={image} alt="preview" className="dropPresent__image" />
                                        }
                                    </div>
                                    
                                    {/* {formik.touched.country || formik.errors.country && <div className='input-error'>{formik.errors.country}</div>} */}
                                </InputContainer>

                            <label htmlFor="passportStylePhoto" className="uploadAvatar__text">Upload your passport <br /> style photo</label>
                        </div>
                        <div className='loginForm__container'>
                            <div className='loginForm__title'>
                                {content?.APPLICATION_FOR_MEMBER_APPROVAL || 'Application for member approval'}
                            </div>
                                <div className="identityType">
                                    <div className={`identityType__item ${identityType === 'id' && 'active'}`} onClick={() => {
                                        formik.setFieldValue('country', '');
                                        formik.setFieldValue('passport_or_driver_license', '');
                                        setCountry(null)
                                        setIdentityType('id')
                                    }}>
                                        {content?.US_STATE_ID || 'US STATE ID'}
                                        <TooltipComponent title={content?.US_STATE_ID_INFO ?? ''} />
                                    </div>
                                    <span className="color-gray">or</span>
                                    <div className={`identityType__item ${identityType === 'passport' && 'active'}`} onClick={() => {
                                        formik.setFieldValue('state', '');
                                        formik.setFieldValue('id_number', '');
                                        setUsaState(null)
                                        setIdentityType('passport');
                                    }}>
                                        {content?.PASSPORT || 'Passport'}
                                        <TooltipComponent title={content?.PASSPORT_INFO ?? ''} />
                                    </div>
                                </div>
                            
                                {
                                    identityType === 'id' && (
                                        <>
                                            <InputContainer>
                                                <label htmlFor='country'>State of issue <span className="required-field"> *</span></label>
                                                <Select 
                                                    options={statesOptions} 
                                                    defaultValue={formik.values.country}
                                                    onChange={(value) => {
                                                        setUsaState(value)
                                                        formik.setFieldValue('state', value.label);
                                                    }}
                                                    value={usaState}
                                                    id="state"
                                                    name="state"
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            backgroundColor: 'transparent',
                                                            height: '64px',
                                                            borderColor: '#272727',
                                                            borderRadius: '6px',
                                                            color: '#FFF'
                                                        }),
                                                        singleValue: (baseStyles) => ({
                                                            ...baseStyles,
                                                            color: '#FFF'
                                                        }),
                                                        input: (baseStyles) => ({
                                                            ...baseStyles,
                                                            color: '#FFF'
                                                        }),
                                                    }}
                                                    className='country-selector'
                                                />
                                                {formik.touched.country && formik.errors.country && <div className='input-error'>{formik.errors.country}</div>}
                                            </InputContainer>

                                        
                                            <Input
                                                type='text'
                                                placeholder='01001082770'
                                                className='loginForm__input loginForm__input--noRounded'
                                                label='US STATE ID'
                                                name='id_number'
                                                error={formik.touched.id_number && formik.errors.id_number}
                                                value={formik.values.id_number}
                                                onChange={formik.handleChange}
                                                // tooltip={'Please enter your passport or driver license number'}
                                                showRequired
                                            />

                                            
                                        </>
                                    )
                                }

                                {
                                    identityType === 'passport' && (
                                        <>
                                            <InputContainer>
                                                <label htmlFor='country'>Country of issue <span className="required-field"> *</span></label>
                                                <Select 
                                                    options={options} 
                                                    defaultValue={formik.values.country}
                                                    onChange={(value) => {
                                                        setCountry(value)
                                                        formik.setFieldValue('country', value.label);
                                                    }}
                                                    value={country}
                                                    id="country"
                                                    name="country"
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            backgroundColor: 'transparent',
                                                            height: '64px',
                                                            borderColor: '#272727',
                                                            borderRadius: '6px',
                                                            color: '#FFF'
                                                        }),
                                                        singleValue: (baseStyles) => ({
                                                            ...baseStyles,
                                                            color: '#FFF'
                                                        }),
                                                        input: (baseStyles) => ({
                                                            ...baseStyles,
                                                            color: '#FFF'
                                                        }),
                                                    }}
                                                    className='country-selector'
                                                />
                                                {formik.touched.country && formik.errors.country && <div className='input-error'>{formik.errors.country}</div>}
                                            </InputContainer>

                                            <Input
                                                type='text'
                                                placeholder='01001082770'
                                                className='loginForm__input loginForm__input--noRounded'
                                                label='Passport Number'
                                                name='passport_or_driver_license'
                                                error={formik.touched.passport_or_driver_license && formik.errors.passport_or_driver_license}
                                                value={formik.values.passport_or_driver_license}
                                                onChange={formik.handleChange}
                                                // tooltip={'Please enter your passport or driver license number'}
                                                showRequired
                                            />
                                        </>
                                    )
                                }

                                <Input 
                                    type='date'
                                    className='loginForm__input loginForm__input--noRounded loginForm__date'
                                    label='Date of birth'
                                    name='dob'
                                    min="1900-01-01"
                                    max="9999-12-12"
                                    error={formik.touched.dob && formik.errors.dob}
                                    value={formik.values.dob}
                                    onChange={formik.handleChange}
                                    showRequired
                                />

                                <Input 
                                    type='string'
                                    className='loginForm__input loginForm__input--noRounded'
                                    label='RA Username'
                                    name='ra_link'
                                    placeholder="Enter your RA Username"
                                    error={formik.touched.ra_link && formik.errors.ra_link}
                                    value={formik.values.ra_link}
                                    onChange={formik.handleChange}
                                    showRequired
                                    tooltip={content?.RA_USERNAME_INFO}
                                />
                        

                                <InputContainer>
                                    <div className="flex flex-jcsb">
                                        <label htmlFor='country'>
                                            {content?.SOCIAL_LINKS || 'Social links'}
                                             <span>
                                                {content?.PUBLIC_ONLY || 'Public only'}
                                                <span className="required-field"> *</span></span>
                                            <TooltipComponent title={content?.SOCIAL_LINKS_INFO || 'Public social links'} />
                                        </label>
                                        <div className="add-link" onClick={addAnotherInput}>
                                            {content?.ADD_LINK || '+ Add link'}
                                        </div>
                                    </div>
                                    
                                    {socialLinks.map((link: any, index: any) => (
                                        <div className="inputWithClose" key={index}>
                                            <StyledInput
                                                value={link}
                                                placeholder="Insert your profile link"
                                                variant="primary"
                                                className="loginForm__input--noRounded loginForm__input--social"
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleLinkChange(e, index)}
                                            />
                                            {index !== 0 && <div className="inputWithClose__close" onClick={() => onSocialLinkRemove(index)}></div> }
                                        </div>
                                    ))}
                                    {formik.errors.socials && <div className='input-error'>{formik.errors.socials}</div>}
                                </InputContainer>

                                {error  && <div className='error-message'>{error}</div>}

                                <div className='loginForm__actions requestVerificationForm__footer'>
                                    <div>
                                        <label className={`styledCheckbox ${(formik.touched.terms && formik.errors.terms) && 'styledCheckbox--error'}`}>
                                            <input 
                                                type="checkbox" 
                                                name="terms" 
                                            />
                                            <p 
                                                className="requestVerificationForm__terms"
                                            >
                                                {
                                                    content?.BY_CHECKING_THIS || 'By checking this you agree to our'
                                                }
                                            </p>
                                        </label>
                                        <p className="requestVerificationForm__terms requestVerificationForm__terms-button" onClick={() => setRulesOpen(true)}>
                                            {content?.HOUSE_RULES_POLICY || 'House Rules & Policy'}
                                        </p>
                                    </div>
                                    
                                    <PopupWrapper isopen={rulesOpen}>
                                        <StyledPopupHeader>
                                            <div className="popup-title">
                                                {content?.HOUSE_RULES || 'House Rules'}
                                            </div>
                                            <div className="close" onClick={()=> setRulesOpen(false)}></div>
                                        </StyledPopupHeader>
                                        <div className="text text--readable text--white">
                                            <HomeRules size='sm' />
                                        </div>
                                    </PopupWrapper>
                                    {/* <TermsAndCondition isOpen={termsPopupOpen} onClose={() => setTermsPopupOpen(false)}/> */}
                                    &nbsp;
                                    <Button type={'submit'} variant='red'>
                                        {content?.COMPLETE || 'Complete'}
                                    </Button>
                                </div>
                        </div>
                    </form>
                </LoadingWrapper>
            </div>
    )
}

export default RequestVerificationForm;