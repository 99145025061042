import React from 'react';
import styledComponent, { css } from 'styled-components';

export interface ITextarea extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: 'primary';
  error?: any;
  label? : string;
  tooltip?: string | null;
  showRequired?: boolean;
}

const StyledTextarea = styledComponent.textarea<ITextarea>`
    background: #000;
    border: 1px solid #272727;
    min-height: 120px;
    color: #fff;
    border-radius: 50px;
    padding: 10px 27px;
    outline: none;
    &::placeholder {
        color: #626262;
    }
    ${(props) =>
        props.variant === 'primary' &&
        css`
            
        `
    }
`;

const TextareaContainer = styledComponent.div`
    display: flex;
    flex-direction: column;
    /* background-color: #000; */
    margin-bottom: 30px;
    .input-error {
        margin-top: 10px;
        color: #FF4747;
        font-size: 9px;
        padding-left: 15px;
    }
    label {
        padding-left: 10px;
        margin-bottom: 13px;
        font-size: 14px;
        color: #fff;
        font-family: "Main Bold"
        span {
            font-size: 10px;
        }
    }
    .add-link {
        color: #fff;
        font-size: 12px;
        cursor: pointer;
    }
`;

export { TextareaContainer };

export default StyledTextarea;