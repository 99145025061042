import { PermissionGuard } from "../../../Components/PermissionGuard/PermissionGuard.component";
import LatestsUsersView from "../../../Views/Admin/LatestsUsers/LatestsUsers.view";
import Layout from "../../../Views/Admin/Layout";
import OverviewView from "../../../Views/Admin/Overview/Overview.view";
import { PermissionsEnum } from "../../../utils/interfaces/permissions.enum";

const DashboardPage = () => {
    return (
        <Layout>
            <PermissionGuard role={PermissionsEnum.LATEST_REQUEST_COMPONENT_VIEW}>
                <LatestsUsersView />
            </PermissionGuard>
            <PermissionGuard role={PermissionsEnum.SHOW_STATISTICS}>
                <OverviewView />
            </PermissionGuard>
        </Layout>
    )
};

export default DashboardPage;