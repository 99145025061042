import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";

import LoadingWrapper from "../Loading.wrapper";
import { IUser } from "../../../utils/interfaces/user.interface";
import UserInfoComponent from "../../../Components/UserInfo/UserInfo.component";
import Title from "../../../Components/Title/Title.component";
import { NavLink } from "react-router-dom";
import { PermissionGuard } from "../../../Components/PermissionGuard/PermissionGuard.component";
import { PermissionsEnum } from "../../../utils/interfaces/permissions.enum";
import { usePermissionGuard } from "../../../hooks/usePermissionsGuard";

const LatestsUsersView = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [latestUsers, setLatestUsers] = useState<IUser[]>([]);
    const canSeeMembers = usePermissionGuard(PermissionsEnum.SEE_MEMBER_DETAILS);
    
    const getLatestsUsers = async (): Promise<void> => {
        await axios.get(`users/latest-users`)
            .then((res: AxiosResponse) => {
                const { data } = res;
                
                setLatestUsers(data);
                setIsLoading(false)
            })
            .catch(e=>{
                console.warn(e);
            });
    };
    
    useEffect(() => {
        getLatestsUsers()
    }, []);
    
    return (
        <>
            <Title title="Latest Requests">
                <PermissionGuard role={PermissionsEnum.MEMBERS_PAGE_VIEW}>
                    <NavLink className='link' to="/admin/members?status=ALL">Browse all</NavLink>
                </PermissionGuard>
            </Title>
            <LoadingWrapper loading={isLoading}>
                {
                    latestUsers.map((user: IUser) => (
                        <UserInfoComponent
                            key={user.id}
                            id={user.id}
                            full_name={user.full_name}
                            email={user.email}
                            socials={user.social_links}
                            avatar={user.profile_photo}
                            request_by={user.invited_by}
                            canSeeMembers={canSeeMembers}
                        />
                    ))
                }
                {latestUsers.length === 0 && <div className="no-data">No pending users</div>}
            </LoadingWrapper>
        </>
    )
};

export default LatestsUsersView;