import {  FC } from 'react'
import './Checkbox.scss'
import { FormikValues } from 'formik'

export const Checkbox:FC<FormikValues> = (props) => {
    return(
        <div className='checkbox'>
            <input type="checkbox" {...props}/>
        </div>
    )
}