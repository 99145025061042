export type TRolesTree = {
  title: string;
  id: string;
};

export interface IPermissionsTree {
  id: string;
  title: string;
  permissions: string;
  roles: TRolesTree[];
}

export const permissionsTree: IPermissionsTree[] = [
  {
    id: 'dashboard',
    title: 'Dashboard module',
    permissions:
      'DASHBOARD_PAGE_VIEW,LATEST_REQUEST_COMPONENT_VIEW,SHOW_STATISTICS',
    roles: [
      {
        title: 'Dashboard page view',
        id: 'DASHBOARD_PAGE_VIEW',
      },
      {
        title: 'Latests request component view',
        id: 'LATEST_REQUEST_COMPONENT_VIEW',
      },
      {
        title: 'Show statistics',
        id: 'SHOW_STATISTICS',
      },
    ],
  },
  {
    id: 'members',
    title: 'Members module',
    permissions:
      'MEMBERS_PAGE_VIEW,SHOW_ALL_MEMBERS,SEE_MEMBER_DETAILS,PENDING_MEMBERS_LIST_VIEW,UNVERIFIED_MEMBERS_LIST_VIEW,VERIFIED_MEMBERS_LIST_VIEW,BANNED_MEMBERS_LIST_VIEW,REGISTERED_MEMBERS_LIST_VIEW,MEMBER_STATUS_CHANGE,SHOW_MEMBER_ROLE,FRIEND_INVITATION,ADD_NOTES,SHOW_ALL_NOTES,SHOW_ONLY_MY_NOTES,SHOW_SCORE',
    roles: [
      {
        title: 'Members page view',
        id: 'MEMBERS_PAGE_VIEW',
      },
      {
        title: 'Show all members',
        id: 'SHOW_ALL_MEMBERS',
      },
      {
        title: 'See member details',
        id: 'SEE_MEMBER_DETAILS',
      },
      {
        title: 'Pending members list view',
        id: 'PENDING_MEMBERS_LIST_VIEW',
      },
      {
        title: 'Unverified members list view',
        id: 'UNVERIFIED_MEMBERS_LIST_VIEW',
      },
      {
        title: 'Verified members list view',
        id: 'VERIFIED_MEMBERS_LIST_VIEW',
      },
      {
        title: 'Banned members list view',
        id: 'BANNED_MEMBERS_LIST_VIEW',
      },
      {
        title: 'Registered members list view',
        id: 'REGISTERED_MEMBERS_LIST_VIEW',
      },
      {
        title: 'Member status change',
        id: 'MEMBER_STATUS_CHANGE',
      },
      {
        title: 'Can member verify',
        id: 'CAN_VERIFY_MEMBER',
      },
      {
        title: 'Can member unverify',
        id: 'CAN_UNVERIFY_MEMBER',
      },
      {
        title: 'Can member ban',
        id: 'CAN_BAN_MEMBER',
      },
      {
        title: 'Can member unban',
        id: 'CAN_UNBAN_MEMBER',
      },
      {
        title: 'Can disable member',
        id: 'CAN_DISABLE_MEMBER',
      },
      {
        title: 'Ask for letter',
        id: 'ASK_FOR_LETTER',
      },
      {
        title: 'Show member role',
        id: 'SHOW_MEMBER_ROLE',
      },
      {
        title: 'Friend invitation',
        id: 'FRIEND_INVITATION',
      },
      {
        title: 'Add notes',
        id: 'ADD_NOTES',
      },
      {
        title: 'Show all notes',
        id: 'SHOW_ALL_NOTES',
      },
      {
        title: 'Show only my notes',
        id: 'SHOW_ONLY_MY_NOTES',
      },
      {
        title: 'Show score',
        id: 'SHOW_SCORE',
      },
    ],
  },
  {
    id: 'invite_members',
    title: 'Invite members module',
    permissions: 'INVITE_MEMBERS_PAGE_VIEW,INVITE_MEMBER,CANCEL_INVITE',
    roles: [
      {
        title: 'Invite members page view',
        id: 'INVITE_MEMBERS_PAGE_VIEW',
      },
      {
        title: 'Invite member',
        id: 'INVITE_MEMBER',
      },
      {
        title: 'Cancel invite',
        id: 'CANCEL_INVITE',
      },
    ],
  },
  {
    id: 'events',
    title: 'Events module',
    permissions:
      'EVENTS_PAGE_VIEW,EVENTS_DETAIL_VIEW,ADD_EVENT,UPDATE_EVENT,DELETE_EVENT',
    roles: [
      {
        title: 'Events page view',
        id: 'EVENTS_PAGE_VIEW',
      },
      {
        title: 'Events detail view',
        id: 'EVENTS_DETAIL_VIEW',
      },
      {
        title: 'Add event',
        id: 'ADD_EVENT',
      },
      {
        title: 'Update event',
        id: 'UPDATE_EVENT',
      },
      {
        title: 'Delete event',
        id: 'DELETE_EVENT',
      },
    ],
  },
  {
    id: 'residents',
    title: 'Residents module',
    permissions:
      'RESIDENTS_PAGE_VIEW,ADD_RESIDENTS,UPDATE_RESIDENTS,DELETE_RESIDENTS',
    roles: [
      {
        title: 'Residents page view',
        id: 'RESIDENTS_PAGE_VIEW',
      },
      {
        title: 'Add resident',
        id: 'ADD_RESIDENTS',
      },
      {
        title: 'Update residents',
        id: 'UPDATE_RESIDENTS',
      },
      {
        title: 'Delete residents',
        id: 'DELETE_RESIDENTS',
      },
    ],
  },
  {
    id: 'content',
    title: 'Content module',
    permissions: 'CONTENT_PAGE_VIEW,UPDATE_CONTENT',
    roles: [
      {
        title: 'Content page view',
        id: 'CONTENT_PAGE_VIEW',
      },
      {
        title: 'Update content',
        id: 'UPDATE_CONTENT',
      },
    ],
  },
  {
    id: 'lostfound',
    title: 'Lost & Found module',
    permissions: 'SHOW_LOST_AND_FOUND,UPDATE_LOST_AND_FOUND_STATUS',
    roles: [
      {
        title: 'Show lost & found',
        id: 'SHOW_LOST_AND_FOUND',
      },
      {
        title: 'Update lost & found status',
        id: 'UPDATE_LOST_AND_FOUND_STATUS',
      },
    ],
  },
  {
    id: 'roles',
    title: 'Roles management module',
    permissions: 'SHOW_ALL_ROLES,ADDING_ADDITIONAL_ROLES,CHANGE_MEMBER_ROLES',
    roles: [
      {
        title: 'Show all roles',
        id: 'SHOW_ALL_ROLES',
      },
      {
        title: 'Adding additional roles',
        id: 'ADDING_ADDITIONAL_ROLES',
      },
      {
        title: 'Change member roles',
        id: 'CHANGE_MEMBER_ROLES',
      },
    ],
  },
];
