import { StyledTitle } from "./Title.styles";

interface ITitle {
    title: string;
    children?: React.ReactNode;
}

const Title: React.FC<ITitle> = ({ title, children}) => {
    return (
        <StyledTitle>
            {title}
            {children}
        </StyledTitle>
    )
}

export default Title;