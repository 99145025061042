import { IUser } from '../../utils/interfaces/user.interface';
import SocialIconsComponent from '../SocialIcons/SocialIcons.component';
import { Button } from '../Button/Button.component';
import './ProfileData.scss';
import { useSignOut } from 'react-auth-kit';
import { NavLink, NavigateFunction, useNavigate } from 'react-router-dom';
import { afterLoginRoute } from '../../utils/helpers/page-route';

export interface IProfileData {
  user: IUser;
}

const ProfileData: React.FC<IProfileData> = ({
  user,
}) => {
  const signOut: () => void = useSignOut();
    
  const navigate: NavigateFunction = useNavigate();
  const logOut = (): void => {
      signOut();
      navigate('/login');
  };

  return (
    <div className="profileData">
      <div className={`profileData__image memberInfo__image memberInfo__image--${user.status} profileData__image--${user.status}}`}>
        <img src={`${process.env.REACT_APP_HOST}/public/users/${user.profile_photo}`} alt="profile" />
        <span className={`profileData__status memberInfo__image-text--${user.status}`}>{user.status}</span>
      </div>
      <div className='profileData__name'>{user.full_name}</div>

      <div className='profileDataBlocks'>

        <div className="profileDataBlocks__item">
          <div className="profileDataBlocks__item-label">
            Email
          </div>
          <div className="profileDataBlocks__item-value" title={user.email}>
            {user.email}
          </div>
        </div>

        <div className="profileDataBlocks__item">
          <div className="profileDataBlocks__item-label">
            Socials
          </div>
          <div className="profileDataBlocks__item-value">
            <SocialIconsComponent socialLinks={user.social_links} />
          </div>
        </div>

        <div className="profileDataBlocks__item">
          <div className="profileDataBlocks__item-label">
            ID Number
          </div>
          <div className="profileDataBlocks__item-value">
            { user.passport_or_driver_license || user.id_number }
          </div>
        </div>

        <div className="profileDataBlocks__item">
          <div className="profileDataBlocks__item-label">
            Phone
          </div>
          <div className="profileDataBlocks__item-value">
            {user.phone}
          </div>
        </div>
      </div>
      <div>
        {
          user?.role?.name !== 'MEMBER' && (
            <NavLink to={afterLoginRoute(user.role.permissions)} className='profileDataBlocks__button profileDataBlocks__button--goto'>
              Go to admin panel
            </NavLink>
          )
        }
        
        <Button className="profileDataBlocks__button" onClick={logOut} variant="stroked-red" type='button'>Logout</Button>
      </div>
    </div>
  );
};

export default ProfileData;
