interface CollapseComponentProps {
  isOpen: boolean;
  onToggle: (isOpen: boolean) => void;
}

const CollapseComponent: React.FC<CollapseComponentProps> = ({ isOpen, onToggle }) => {
  return (
    <div className={`collapse ${!isOpen && 'collapse-close'}`} onClick={() => onToggle(!isOpen)}>
      {isOpen ? 'Collapse' : 'Expand'}
    </div>
  );
};

export default CollapseComponent;