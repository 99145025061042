import ReactDOM from 'react-dom/client';
import App from './App';

import './assets/styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'

import { axiosSetup } from './utils/axios.setup';

axiosSetup()

const rootElement = document.getElementById('root') as HTMLElement;

const root: ReactDOM.Root = ReactDOM.createRoot(rootElement);

root.render(<App />);