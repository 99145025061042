import { useNavigate, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { ImageCard } from "../../../Components/ImageCard/ImageCard";
import { IResident } from "../../../utils/interfaces/residents.interface";
// import { useBreakpoint } from "../../../hooks/useBreakpoint";
// import { LogoLoader } from "../../../Components/LogoLoader/LogoLoader.component";
import './ResidentDetail.scss';
import { LangsContext } from "../../../context/LangsContext";

const ResidentDetailPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ resident, setResident ] = useState<IResident | null>(null);
    const [ lastResident, setLastResident ] = useState<IResident[] | null>(null);

    const content = useContext(LangsContext);
    
    useEffect(()=>{
        setResident(null);
        getLastResident();
        getResident();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id]);
    
    const getResident = () => {
        axios.get(`/residents/public/${id}`).then((res:AxiosResponse) => {
            setResident(res.data);
        })
    }

    const getLastResident = () => {
        axios.get(`/residents/public/?limit=3&page=1&skip=${id}`).then((res:AxiosResponse) => {
            setLastResident(res.data);
        })
    }
    
    return <>
        {
            !resident ? '' : 
            <>
                <div className="resident-detail">
                    <div className="resident-detail__cover">
                        <div className="resident-detail__image">
                            <ImageCard 
                                url={`${process.env.REACT_APP_HOST}/public/residents/${resident.photo_url}`}
                                alt={resident.name}                        
                            />
                        </div>
                        <div className="resident-detail__tags">
                            {
                                resident.soundcloud_link && 
                                    <a 
                                        href={resident.soundcloud_link} 
                                        target="_blank"
                                        rel="noreferrer"
                                        className="resident-detail__tag resident-detail__social resident-detail__social--soundcloud"
                                    >&nbsp;</a>
                            }
                            {
                                resident.instagram_link && 
                                    <a 
                                        href={resident.instagram_link} 
                                        target="_blank"
                                        rel="noreferrer"
                                        className="resident-detail__tag resident-detail__social resident-detail__social--instagram"
                                    >&nbsp;
                                    </a>
                            }
                            {
                                resident.ra_link && 
                                    <a 
                                        href={resident.ra_link} 
                                        target="_blank"
                                        rel="noreferrer"
                                        className="resident-detail__tag resident-detail__social resident-detail__social--ra"
                                    >&nbsp;
                                    </a>
                            }
                        </div>
                    </div>
                    <h1 className="resident-detail__name resident-detail__title">{resident.name}</h1>
                    <p className="resident-detail__bio"  dangerouslySetInnerHTML={{__html: resident.bio.replaceAll('\r\n','</br>')}}/>
                </div>
                <div className="resident-detail__clear"></div>
                <div className="resident-detail__others">
                    <div className="resident-detail__others-title">
                        {content?.OTHER_RESIDENTS || 'OTHER RESIDENTS'}
                    </div>
                    <div className="resident-detail__others-content">
                        {lastResident?.map((resident:IResident) => 
                            <div className="resident-detail__others-card" key={resident.id}>
                                <div className="resident-detail__others-cover">
                                    <ImageCard
                                        onClick={()=>navigate(`/residents/${resident.id}`)}
                                        url={`${process.env.REACT_APP_HOST}/public/residents/${resident.photo_url}`}
                                        alt={resident.name}
                                    />
                                </div>
                                <div className="resident-detail__others-name">{resident.name}</div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        }   
    </>
}

export default ResidentDetailPage;