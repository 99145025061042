import { useContext } from 'react';
import './UserMiniInfo.scss';
import { LangsContext } from '../../context/LangsContext';

export interface IUserMiniInfo {
  full_name: string;
}

const UserMiniInfo: React.FC<IUserMiniInfo> = ({
  full_name,
}) => {

  const content = useContext(LangsContext);
  
  return (
    <div className="userMiniInfo">
      {content?.HELLO || 'Hello'} <span>{full_name}</span>,
      <br />
      <br />
      {content?.HOW_TO_ACTIVE_INFO || 'To activate your account you must complete verification form and purchase membership from RA (follow link in email)'}
    </div>
  );
};

export default UserMiniInfo;
