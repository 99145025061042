import { Tooltip } from 'react-tooltip'
import './Tooltip.styles.scss';

interface ITooltipProps {
    title: string;
}

const TooltipComponent: React.FC<ITooltipProps> = ({ title }) => {
    return (
        <>
            <span
                data-tooltip-id="tooltip"
                data-tooltip-content={title}
                className="tooltip"
            >
                [?]
            </span>
            <Tooltip
                id="tooltip"
                style={{ backgroundColor: "#272727", borderRadius: '5px', maxWidth: '150px', textAlign: 'center',color: "#fff", fontSize: '12px', border: '1px solid #272727' }}
            />
        </>
    );
};

export default TooltipComponent;