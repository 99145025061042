import { AuthProvider } from "react-auth-kit";
import { RouterProvider } from 'react-router-dom';
import { router } from "./router";
import { LangsProvider } from "./context/LangsContext";

const App: React.FC = () => {
  
  return (
    <AuthProvider authType="localstorage" authName="_auth">
      <LangsProvider>
        <RouterProvider router={router} />
      </LangsProvider>
    </AuthProvider>
  );
};

export default App;
