import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getToken } from './services/users.service';

export const axiosSetup = () => {
  axios.interceptors.request.use(
    function (config: InternalAxiosRequestConfig) {
      const token: string | undefined = getToken();

      config.baseURL = `${process.env.REACT_APP_HOST}/`;
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    function (error: AxiosError) {
      console.log(error);
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    error => {
      if (error.response.status === 401) {
        // // authService.logout();
        // const club =
        //   localStorage.getItem('club') &&
        //   JSON.parse(localStorage.getItem('club') as any);
        // if (club) {
        //   window.location.href = `/login/${club.slug}`;
        // } else {
        //   window.location.href = '/';
        // }
      }
      if (error.response.status === 403) {
        window.location.href = '/forbidden';
      } else {
        return Promise.reject(error);
      }
    }
  );
};
