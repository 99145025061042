import { Loader } from "../../Components/Loader/Loader.component";

interface LoadingWrapperProps {
  loading: boolean;
  children: React.ReactNode;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ loading, children }) => {
  return <>{loading ? <Loader /> : <>{children}</>}</>;
};

export default LoadingWrapper;