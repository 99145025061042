import { useContext, useEffect, useState } from "react";
import { FormikValues, useFormik } from "formik";
import { useNavigate,  NavigateFunction, useSearchParams } from 'react-router-dom';
import axios, { AxiosError } from "axios";
import * as Yup from "yup";

import {Button} from '../../Components/Button/Button.component';
import Input from '../../Components/Input/Input.component';
import LoadingWrapper from '../../Views/Admin/Loading.wrapper';
import { LangsContext } from "../../context/LangsContext";

const loginValidationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    password_repeat: Yup.string().required("Password is required"),
});

const RecoverPassword: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate() as NavigateFunction;

    const content = useContext(LangsContext);

    const onSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await axios.post("users/recover-password",values)
                .then(() => navigate('/success?view=recover-password'));
        } catch (err) {
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
                
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
          token: "",
          password: "",
          password_repeat: "",
        },
        validationSchema: loginValidationSchema,
        onSubmit,
    });

    useEffect(() => {
        const hash = searchParams.get('hash')
        
        if(hash) {
            formik.setFieldValue('token', searchParams.get('hash'));
        }
        else {
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    return (
        <div className="login loginMember">
            <div className="login__container ">
                <div className='login__column login__column--form'>
                    <div className='loginForm'>
                        <LoadingWrapper loading={isLoading}>
                            <div className='loginForm__container'>
                                <div className='loginForm__title'>
                                    {content?.CREATE_NEW_PASSWORD || 'Create a new password'}
                                </div>
                                <form onSubmit={formik.handleSubmit}>
                                    <Input 
                                        type='password'
                                        placeholder='******'
                                        className='loginForm__input loginForm__input--noRounded'
                                        label='Password'
                                        name='password'
                                        autoComplete="current-password"
                                        error={formik.touched.password && formik.errors.password}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                    />

                                    <Input 
                                        type='password'
                                        placeholder='******'
                                        className='loginForm__input loginForm__input--noRounded'
                                        label='Repeat password'
                                        name='password_repeat'
                                        autoComplete="current-password"
                                        error={formik.touched.password_repeat && formik.errors.password_repeat}
                                        value={formik.values.password_repeat}
                                        onChange={formik.handleChange}
                                    />

                                    {error  && <div className='error-message'>{error}</div>}

                                    <div className='loginForm__actions'>
                                        &nbsp;
                                        <Button type={'submit'} variant='red' disabled={!(formik.isValid && formik.dirty)}>Login</Button>
                                        {/* <Button type={'submit'} variant='green'>Submit</Button> */}
                                    </div>
                                </form>
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecoverPassword;