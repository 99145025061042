import { IUser } from '../interfaces/user.interface';

export const getCurrentUser = (): IUser | undefined => {
    let user = localStorage.getItem('_auth_state');
    if (user) {
        return JSON.parse(user);
    }
};

export const getToken = (): string | undefined => {
    let token: string | null = localStorage.getItem('_auth');
    
    return token ? token : undefined;
};