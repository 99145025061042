import './RequestComponent.scss'

export interface IRequestComponent {
    image?: string;
    text: string;
    bg?: 'transparent'
}

const RequestComponent: React.FC<IRequestComponent> = ({text = '', image, bg }) => {
    return (
        <div className="requested" style={{ 
            backgroundColor: bg === 'transparent' ? 'transparent' : ''
        }}>
            <div className='requested-container'>
                {
                    !image ? <div className="requested-icon"></div> : <div className="requested-image"><img src={image} alt={text} /></div>
                }
                <div className="requested-content">
                    <div className="requested-content__title">
                        {image ? 'Requested by' : 'Requested date'}
                    </div>
                    <div className="requested-content__text" title={text}>
                        <div>{image ? text : text.toString().split('T')[0]}</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RequestComponent;