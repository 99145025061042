import { useState } from "react";
import { FormikValues, useFormik } from "formik";
import { useNavigate, NavLink, NavigateFunction} from 'react-router-dom';
import axios, { AxiosError } from "axios";
import * as Yup from "yup";

import './ForgotPassword.scss';

import {Button} from '../../Components/Button/Button.component';
import Input from '../../Components/Input/Input.component';
import LoadingWrapper from '../../Views/Admin/Loading.wrapper';

const loginValidationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
});

const ForgotPassword: React.FC = () => {
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate() as NavigateFunction;

    const onSubmit = async (values: any) => {
        setIsLoading(true);

        try {
            await axios.post("users/forgot-password",values)
                .then(() => navigate('/success?view=forgot-password'))
        } catch (err) {
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
                
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
          email: "",
        },
        validationSchema: loginValidationSchema,
        onSubmit,
    });
    
    return (
        <div className="login loginMember">
            <div className="login__container ">
                <div className='login__column login__column--form'>
                    <div className='loginForm'>
                        <LoadingWrapper loading={isLoading}>
                            <div className='loginForm__container'>
                                <div className='loginForm__title'>Reset password</div>
                                <form onSubmit={formik.handleSubmit}>
                                    <Input 
                                        type='text'
                                        placeholder='Email: example@gmail.com'
                                        className='loginForm__input loginForm__input--noRounded'
                                        label='Email'
                                        name='email'
                                        autoComplete="username"
                                        error={formik.touched.email && formik.errors.email}
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                    />

                                    {error  && <div className='error-message'>{error}</div>}

                                    <div className='loginForm__actions'>
                                        <NavLink to='/login' className='loginForm__link loginForm__link--backTo'>back to login</NavLink>
                                        <Button type={'submit'} variant='red'>Reset</Button>
                                    </div>
                                </form>
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;