import React, { FC } from "react";
import { useAuthUser } from "react-auth-kit";
import { PermissionsEnum } from "../../utils/interfaces/permissions.enum";

interface IPermissionGuard {
    children: React.ReactElement,
    role: PermissionsEnum,
}

export const PermissionGuard:FC<IPermissionGuard> = ({children,role}) => {
    const auth = useAuthUser();
    const permissions = auth()?.role.permissions;
    const hasRole = permissions.find((item:PermissionsEnum) => item === role);
    // console.log(hasRole,permissions);
    return hasRole ? React.cloneElement(children) : null;
}
