import { FormikValues, useFormik } from 'formik';
import PopupWrapper, { StyledPopupHeader } from '../../Views/Admin/Popup.wrapper';
import { IInvite, IUser } from '../../utils/interfaces/user.interface';
import { Button } from '../Button/Button.component';
import RequestComponent from '../RequestComponent/RequestComponent';

import './LetterPopupForm.scss';
import * as Yup from 'yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import LoadingWrapper from '../../Views/Admin/Loading.wrapper';

const letterValidationSchema = Yup.object({
  text: Yup.string().required("Text is required"),
});

const LetterPopupForm: React.FC<{
  isopen: boolean;
  onClose: () => void;
  invite: IInvite,
  user: IUser,
  request_by: IUser,
}> = ({ isopen, onClose, invite, user, request_by }) => {
  const [loading, setLoading] = useState<boolean>(false)
  
  const onSubmit = async (values: any) => {
    setLoading(true)
    axios.post(`invites/send-letter/${invite.id}` , {
      letter: values.text
    })
    .then(() => {
      console.log('success')
      invite.letter = values.text
      setLoading(false)
    })
    .catch((err) => {
      alert(err)
    });
  }

  useEffect(() => {
console.log(123)
  }, [invite])
  
  const formik: FormikValues = useFormik({
    initialValues: {
      text: "",
    },
    validationSchema: letterValidationSchema,
    onSubmit,
});
  
  return (
    <PopupWrapper isopen={isopen}>
      <StyledPopupHeader>
        <div className="popup-title">Write letter for {user?.full_name}</div>
        <div className="close" onClick={onClose}></div>
      </StyledPopupHeader>
      <div className="letter-popup">
        {
          invite.letter && (
            <div className='letter-popup__text'>
              {invite.letter}
            </div>
          )
        }
        {
          !invite.letter && (
            <LoadingWrapper loading={loading}>
              <form onSubmit={formik.handleSubmit}>
                <div className='letter-popup__text'>
                  <textarea 
                      placeholder='Write letter why you want to invite this user'
                      className='letter-popup__textarea'
                      name='text'
                      autoComplete="username"
                      onChange={formik.handleChange}
                  />

                  {formik.touched.email && formik.errors.email && <div className='error'>{formik.errors.email}</div>}
                  
                  <br />
                  <br />
                  <div className='loginForm__actions flex-jcfe'>
                    <Button type={'submit'} variant='red'>Send</Button>
                  </div>
                </div>
              </form>
            </LoadingWrapper>
          )
        }
        
        <br />
        <br />
        <RequestComponent 
            bg={'transparent'}
            text={request_by?.full_name || ''}
            image={`${process.env.REACT_APP_HOST}/public/users/${request_by?.profile_photo}`}
        />
      </div>
    </PopupWrapper>
  );
};

export default LetterPopupForm;
