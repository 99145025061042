import { FormikValues, useFormik } from "formik"
import Title from "../../../Components/Title/Title.component"
import Layout from "../../../Views/Admin/Layout"
import { RoleModule } from "../../../Components/RoleModule/RoleModule.component";
import { permissionsTree } from "../../../constants/permissions.constant";
import Input from "../../../Components/Input/Input.component";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ConfirmPopup from "../../../Components/ConfirmPopup/ConfirmPopup";
import { StyledButton } from "../../../Components/Button/Button.styles";
import { Button } from "../../../Components/Button/Button.component";
import LoadingWrapper from "../../../Views/Admin/Loading.wrapper";
import * as Yup from 'yup';
import './UserRolesEdit.scss';
import { usePermissionGuard } from "../../../hooks/usePermissionsGuard";
import { PermissionsEnum } from "../../../utils/interfaces/permissions.enum";
import { toast } from "react-toastify";
import { toastOptions } from "../../../utils/helpers/toast.options";

const validationSchema = Yup.object({
    name: Yup.string().required("Role name is required"),
});

export const UserRolesEdit = () => {
    const { id }  = useParams();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const canChange = usePermissionGuard(PermissionsEnum.ADDING_ADDITIONAL_ROLES);


    const sendResponse = (values: FormikValues) => {
        if(!canChange) return;
        const {roles} = values || {};
        axios.post('/roles/assignPermissions',{
            permissions : [
                {
                    roleId:id, 
                    permissions:roles
                }
            ]
        }).then(() => {
            toast.success(`Permissions are assigned`, toastOptions)
        }).catch((e: AxiosError<{message: string | null}>) => {
            if(e?.response?.data?.message) {
                setError(e?.response?.data?.message)
                toast.error(e?.response?.data?.message, toastOptions)
            }
            else {
                setError(e.message)
            }
        })
    }

    const formik = useFormik({
        initialValues:{
            name:"",
            roles:[] as string[],
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            sendResponse(values)
        }
    })
    
    const onPopupAction = async (trueOrFalse: boolean): Promise<void> => {
        if(!canChange) return;
        setIsPopupOpen(false)
        
        if(trueOrFalse) {
            await axios.delete(`roles/${id}`)
                .then(() => {
                    navigate('/admin/user-roles')
                })
                .catch((e: AxiosError<{message: string}>) => {
                    if(e.response) {
                        setError(e?.response.data.message)
                    }
                    else {
                        setError('Something went wrong! Please try again later.')
                    }
                })
        }
    }

    useEffect(()=>{
        axios.get(`/roles/${id}`).then((data:AxiosResponse)=>{
            formik.setFieldValue('name',data.data.name)
            formik.setFieldValue('roles',data.data.permissions)
            setIsLoading(false)
        }).catch((e:AxiosError)=>{
            setError(e.message)
        })
        // eslint-disable-next-line
    },[id])

    
    return(
        <Layout>
            <LoadingWrapper loading={isLoading}>
                <Title title="Update Role"/>
                <form className="edit-roles" onSubmit={formik.handleSubmit}>
                    <Input 
                        label="Role name"
                        type='text'
                        placeholder='Type name "Content Manager"'
                        className='loginForm__input--noRounded'
                        name='name'
                        error={formik.touched.name && formik.errors.name}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    <div className="edit-roles__content">
                        {
                            permissionsTree.map((permission) => 
                                <RoleModule
                                    key={permission.id} 
                                    title={permission.title} 
                                    permissions={permission.permissions} 
                                    roles={permission.roles}
                                    setField={formik.setFieldValue}
                                    formRoles={formik.values.roles}
                                    handleChange={formik.handleChange}
                                />
                            )
                        }
                    </div>
                    {error && <div className='error-message'>{error}</div>}

                    <ConfirmPopup
                        label={'Are you sure you want to delete the role?'}
                        text={'Role will be deleted permanently.'}
                        onAction={onPopupAction}
                        isopen={isPopupOpen}
                    />

                    <div className='flex flex-jcsb mt-20'>
                        {
                            canChange && <>
                                <Button type={'button'} onClick={() => setIsPopupOpen(true)} variant='stroked-red' className='sm'>delete role</Button>
                                <StyledButton type={'submit'} variant='red' className='sm'>update role</StyledButton>
                            </>
                        }
                    </div>
                </form>
            </LoadingWrapper>
        </Layout>
    )
}

