import './Notes.scss'

import Title from "../../../Components/Title/Title.component"
import { INote, IUser } from '../../../utils/interfaces/user.interface';
import { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import LoadingWrapper from '../Loading.wrapper';
import PopupWrapper from '../Popup.wrapper';
import { StyledPopupHeader } from '../Popup.wrapper';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';
import { toast } from 'react-toastify';
import { toastOptions } from '../../../utils/helpers/toast.options';

interface NotesViewProps {
    user: IUser;
    onNoteAdded: (note: INote) => void;
}

const NoteItem: React.FC<INote> = (note) => {
    return (
        <div className="archiveItem" key={note.id}>
            <div className='archiveItem__info'>
                <div className={`archiveItem__vote archiveItem__vote--${note.vote}`}>{note.vote}</div>
                <div className='archiveItem__info-noted'>
                    Noted by 
                    <div className='archiveItem__info-noted__name'>
                        {note.created_by.full_name}
                    </div>
                </div>
            </div>
            
            <div className="archiveItem__description">
                {note.description}
            </div>
            <div className="archiveItem__date">
                {note.created_at.toString().split('T')[0]}
            </div>
        </div>
    )
}

const NotesView: React.FC<NotesViewProps> = ({user, onNoteAdded}) => {
    const [notes, setNotes] = useState<INote[]>(user.notes);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const [error, setError] = useState<string | null>(null);
    const [vote, setVote] = useState<number | null>(null);
    const [description, setDescription] = useState<string>('');

    const handleSendNotes = async (): Promise<void> => {
        if (!vote) return setError('Please select a vote');
        if (description.length === 0) return setError('Please add a note');

        setIsLoading(true)

        await axios.post(`/notes`, {
            vote,
            description: description,
            user_id: user.id
        })
        .then((res: AxiosResponse) => {
            const {data} = res;

            resetForm();
            setNotes([data, ...notes]);
            onNoteAdded(data)
        
            toast.success(`Note added`, toastOptions)
        })
    }

    const handleTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;

        setDescription(value)
    }

    const resetForm = (): void => {
        setVote(null);
        setError(null);
        setDescription('');
        setIsLoading(false)

        document.querySelectorAll('.notesForm__num').forEach((el: Element) => {
            el.classList.remove('active');
        });
    }

    const onVoteClick = async (e: React.MouseEvent<HTMLDivElement>) => {
        const vote = e.currentTarget.innerText;

        
        document.querySelectorAll('.notesForm__num').forEach((el: Element) => {
            el.classList.remove('active');
        });
        
        e.currentTarget.classList.add('active');
        
        setVote(parseInt(vote));
    }

    const [isNotePopupOpen, setIsNotePopupOpen] = useState<boolean>(false);
    const showNotes = usePermissionGuard(PermissionsEnum.SHOW_ALL_NOTES);
    const showMyNotes = usePermissionGuard(PermissionsEnum.SHOW_ONLY_MY_NOTES);
    const addNotes = usePermissionGuard(PermissionsEnum.ADD_NOTES);
    return(
        addNotes || showMyNotes || showNotes ? <div className="notes">
            <Title title="Notes" />
            {addNotes && <div className="notesForm">
                <div className="notes-title">Quick note</div>
                <div className="notesForm__numbers">
                    {
                        [1, 2, 3, 4, 5].map((num) => (
                            <div
                                key={num}
                                onClick={(e: React.MouseEvent<HTMLDivElement>) => onVoteClick(e)}
                                className={`notesForm__num notesForm__num--${num}`}
                            >
                                {num}
                            </div>
                        ))
                    }
                </div>

                <textarea 
                    className="notesForm__description" 
                    onChange={handleTextArea} 
                    value={description} 
                    placeholder="Type note here"
                ></textarea>

                <div className="notesForm__error">{error}</div>

                <div className="notesFlex notesFlex--jcfe">
                    <button  className="notesForm__btn" onClick={handleSendNotes}>Add note</button>
                </div>

            </div>}

            {(showNotes || showMyNotes) && <>
                <PopupWrapper isopen={isNotePopupOpen} view='note'>
                    <div className="archive">
                        <StyledPopupHeader>
                            <div className="popup-title">Note archive</div>
                            <div className="close" onClick={() => setIsNotePopupOpen(!isNotePopupOpen)}></div>
                        </StyledPopupHeader>

                        {notes.map((note: INote) => <NoteItem {...note} key={note.id} />)}
                    </div>
                </PopupWrapper>

                <div className="archive">
                    <div className="notes-title">Archive</div>
                    <LoadingWrapper loading={isLoading}>
                        { notes.slice(0, 3).map((note: INote) => <NoteItem {...note} key={note.id} />) }

                        {notes.length > 3 && <div className="archive__more" onClick={() => setIsNotePopupOpen(!isNotePopupOpen)}>Show more</div>}

                        {notes.length === 0 && <div className='archiveItem__empty'>No notes yet</div>}
                    </LoadingWrapper>
                </div>
            </>}
        </div> : null
    )
}

export default NotesView;