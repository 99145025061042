import { PermissionsEnum } from "../interfaces/permissions.enum";

// const permissionRoutes: Partial<Record<PermissionsEnum, string>> | any = {
//     [PermissionsEnum.DASHBOARD_PAGE_VIEW]: '/admin',
//     [PermissionsEnum.SHOW_ALL_MEMBERS]: '/admin/members?status=ALL',
//     [PermissionsEnum.INVITE_MEMBERS_PAGE_VIEW]: '/admin/invites?status=INVITED',
//     [PermissionsEnum.EVENTS_PAGE_VIEW]: '/admin/events?status=ALL',
//     [PermissionsEnum.SHOW_ALL_ROLES]: '/admin/user-roles',
//     [PermissionsEnum.SHOW_LOST_AND_FOUND]: '/admin/lost-and-found?status=ALL',
//     [PermissionsEnum.RESIDENTS_PAGE_VIEW]: '/admin/residents',
//     [PermissionsEnum.CONTENT_PAGE_VIEW]: '/admin/content',
// };

// for (const permission of permissions) {
//     if (permissionRoutes[permission]) {
//         navigate(permissionRoutes[permission]);
//         break;
//     }
// }

export const afterLoginRoute = (permissions: PermissionsEnum[]): string => {
    if(!permissions) {
        return '/';
    }

    if(permissions.includes(PermissionsEnum.DASHBOARD_PAGE_VIEW)) {
        return '/admin';
    }

    if(permissions.includes(PermissionsEnum.SHOW_ALL_MEMBERS) && permissions.includes(PermissionsEnum.MEMBERS_PAGE_VIEW)) {
        return '/admin/members?status=ALL';
    }

    if(permissions.includes(PermissionsEnum.INVITE_MEMBERS_PAGE_VIEW)) {
        return '/admin/invites?status=INVITED';
    }

    if(permissions.includes(PermissionsEnum.EVENTS_PAGE_VIEW)) {
        return '/admin/events?status=ALL';
    }

    if(permissions.includes(PermissionsEnum.SHOW_ALL_ROLES)) {
        return '/admin/user-roles';
    }

    if(permissions.includes(PermissionsEnum.SHOW_LOST_AND_FOUND)) {
        return '/admin/lost-and-found?status=ALL';
    }

    if(permissions.includes(PermissionsEnum.RESIDENTS_PAGE_VIEW)) {
        return '/admin/residents';
    }

    if(permissions.includes(PermissionsEnum.CONTENT_PAGE_VIEW)) {
        return '/admin/content';
    }

    return '/admin'
}