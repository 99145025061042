import { IUser } from '../../utils/interfaces/user.interface';
import RequestComponent from '../RequestComponent/RequestComponent';
import { StyledButton } from '../Button/Button.styles';
import { EInvitesStatus } from '../../utils/interfaces/invite-status.enum';

export interface IUserInfoComponentProps {
  id: string;
  email: string;
  request_by: IUser;
  status: EInvitesStatus;
  canCancel:boolean;
  onCancel: (id: string) => void;
}

const InvitesListComponent: React.FC<IUserInfoComponentProps> = ({
  email,
  id,
  request_by,
  status,
  canCancel,
  onCancel
}) => {
  return (
    <div className="userInfo">
      {request_by && (
        <RequestComponent
          image={`${process.env.REACT_APP_HOST}/public/users/${request_by?.profile_photo}`}
          text={request_by?.full_name}
        />
      )}

      <div className="userInfoContainer">
        <div className="userInfo__container">
          <div className="userInfo__text userInfo__text--lg">
            <div className="userInfo__text-title">Email</div>
            <div className="userInfo__text-text">{email}</div>
          </div>
        </div>
        {
            (status === EInvitesStatus.INVITED  && canCancel)&& (
                <div className="userInfo__actions">
                    <StyledButton variant="cancel" onClick={() => onCancel(id)}>
                      Cancel
                    </StyledButton>
                </div>
            )
        }
        
      </div>
    </div>
  );
};

export default InvitesListComponent;
