import './Members.scss';

import Title from "../../../Components/Title/Title.component";
import Layout from "../../../Views/Admin/Layout"
import { useEffect, useState } from 'react';
import { IUser } from '../../../utils/interfaces/user.interface';
import axios, {AxiosResponse} from 'axios';
import { useSearchParams } from 'react-router-dom';
import UserInfoComponent from '../../../Components/UserInfo/UserInfo.component';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from '../../../Components/Loader/Loader.component';
import { useDebounce } from 'use-debounce';
import EndMessage from '../../../Components/EndMessage/EndMessage.component';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';

const MembersPage: React.FC = () => {
    const [members, setMembers] = useState<IUser[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState<string | null>(searchParams.get("status"))
    const [page, setPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [value] = useDebounce(searchText, 300);
    const canSeeMembers = usePermissionGuard(PermissionsEnum.SEE_MEMBER_DETAILS);

    const getMembers = async (action?: 'next' | null) => {
        let url: string = `users/list?limit=10&page=${page}`;

        value && (url += `&search=${value}`)
        
        status && status !== 'ALL' && (url += `&status=${status}`)
        
        await axios.get(url)
            .then((res: AxiosResponse) => {
                const { data } = res;
                
                data.length < 10 && setHasMore(false)
                action === 'next' ? setMembers([...members, ...data]) : setMembers(data);

                setPage(page + 1)
            })
    }

    const changeState = (status: string) => (): void => {
        const currentStatus = searchParams.get("status")
        const isSameStatus = currentStatus === status;

        if(isSameStatus) return;

        setStatus(status)
        // setSearchText(null)
        setPage(1)
        setMembers([])
        setHasMore(true)
        setSearchParams({status})
    }

    const searching = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setPage(1)
        setSearchText(e.target.value)
    };

    useEffect(() => {
        getMembers();
        // eslint-disable-next-line
    }, [value, searchParams])
    
    return <Layout>
        <PermissionGuard role={PermissionsEnum.MEMBERS_PAGE_VIEW}>
            <>
                <Title title={'Members'} />
                <div className="navigation">
                    <div className='navigation__container'>
                        <div onClick={changeState('ALL')} className={`navigation__item ${status === 'ALL' && 'active'}`}>ALL</div>
                        <PermissionGuard role={PermissionsEnum.PENDING_MEMBERS_LIST_VIEW}>
                            <div onClick={changeState('PENDING')} className={`navigation__item ${status === 'PENDING' && 'active'}`}>Pending</div>
                        </PermissionGuard>
                        <PermissionGuard role={PermissionsEnum.UNVERIFIED_MEMBERS_LIST_VIEW}>
                            <div onClick={changeState('UNVERIFIED')} className={`navigation__item ${status === 'UNVERIFIED' && 'active'}`}>Unverified</div>
                        </PermissionGuard>
                        <PermissionGuard role={PermissionsEnum.VERIFIED_MEMBERS_LIST_VIEW}>
                            <div onClick={changeState('VERIFIED')} className={`navigation__item ${status === 'VERIFIED' && 'active'}`}>Verified</div>
                        </PermissionGuard>
                        <PermissionGuard role={PermissionsEnum.BANNED_MEMBERS_LIST_VIEW}>
                            <div onClick={changeState('BLOCKED')} className={`navigation__item ${status === 'BLOCKED' && 'active'}`}>Banned</div>
                        </PermissionGuard>
                        <PermissionGuard role={PermissionsEnum.REGISTERED_MEMBERS_LIST_VIEW}>
                            <div onClick={changeState('REGISTERED')} className={`navigation__item ${status === 'REGISTERED' && 'active'}`}>Registered</div>
                        </PermissionGuard>
                    </div>
                    <input type='text' onChange={searching} value={searchText ? searchText : ''} placeholder='Search member...' className="navigation__search" />
                </div>

                {
                    <InfiniteScroll
                        dataLength={members.length} 
                        next={() => getMembers('next')}
                        hasMore={hasMore}
                        loader={<Loader />}
                        endMessage={<EndMessage />}
                    >
                        {
                            members.map((user: IUser) => (
                                <UserInfoComponent
                                    key={user.id}
                                    id={user.id}
                                    full_name={user.full_name}
                                    email={user.email}
                                    socials={user.social_links}
                                    avatar={user.profile_photo}
                                    request_by={user.invited_by}
                                    canSeeMembers={canSeeMembers}
                                />
                            ))
                        }
                    </InfiniteScroll>
                }
            </>
        </PermissionGuard>
    </Layout>
}

export default MembersPage;