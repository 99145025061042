import TooltipComponent from "../Tooltip/Tooltip.component";
import StyledInput, { IInput, InputContainer } from "./Input.styles";

const Input: React.FC<IInput> = ({ variant, label, id, error, tooltip, showRequired, ...rest }) => {
    
    return (
        <InputContainer>
            {
                label && <label htmlFor={id}>
                    {label}
                    {showRequired && <span className="required-field"> *</span>}
                    {
                        tooltip && <TooltipComponent title={tooltip} />
                    }
                </label>
            }
            <StyledInput error={error} variant={variant} id={id} {...rest} />
            {error && <div className='input-error'>{error}</div>}
        </InputContainer>
    );
};

export default Input;