import { useEffect, useState } from 'react';
import { IUser } from '../../utils/interfaces/user.interface';
import './MemberInfo.scss';
import SocialIconsComponent from '../SocialIcons/SocialIcons.component';
import Toggle from 'react-toggle'
import "react-toggle/style.css" // for ES6 modules
import axios from 'axios';
import { toast } from 'react-toastify';
import { EUserStatus } from '../../utils/interfaces/user-status.enum';
import RegisteredDefaultImage from '../../assets/images/registered-default-image.png';
import { PermissionGuard } from '../PermissionGuard/PermissionGuard.component';
import { PermissionsEnum } from '../../utils/interfaces/permissions.enum';
import Select from "react-select";
import { InputContainer } from '../Input/Input.styles';
import { IUserRoles } from '../../Pages/Admin/UserRoles/UserRoles.page';
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import { usePermissionGuard } from '../../hooks/usePermissionsGuard';
import { toastOptions } from '../../utils/helpers/toast.options';

const MemberInfo: React.FC<{ user: IUser }> = ({ user }) => {
  const [isToggleDisabled, setIsToggleDisabled] = useState<boolean>(false);
  const [roles, setRoles] = useState<{value: string, label: string}[]>([])
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [role, setRole] = useState<{value: string, label: string}>({value: '', label: ''})
  const [selectValue, setSelectValue] = useState<{value: string, label: string}>(user.role ? {value: user.role.id, label: user.role.name} : {value: '', label: ''})
  const hasRoleViewPermission = usePermissionGuard(PermissionsEnum.SHOW_ALL_ROLES);
  const hasShowMemberRolePermission = usePermissionGuard(PermissionsEnum.SHOW_MEMBER_ROLE);

  const handleInvitationToggle = async (): Promise<void> => {
    setIsToggleDisabled(true)

    user.can_invitation = !user.can_invitation

    await axios.put(`users/${user.id}`, { 
        can_invitation: String(user.can_invitation)
      })
      .then(() => {
        setIsToggleDisabled(false)
        if(user.can_invitation) {
          toast.success(`Friend invitation enabled 🦄`, toastOptions)
        }
        else {
          toast.warning(`Friend invitation disabled 🦄`, toastOptions)
        }
      })
  }

  const onPopupAction = async (action: boolean) => {
    if(action) {
      setIsPopupOpen(false)
      
      await axios.put(`users/assign-role/${user.id}`, { 
        role: role.value
      })

      setSelectValue(role)

      toast.success(`Role changed🕺`, toastOptions)
    }
    else {
      setIsPopupOpen(false)
    }
  }

  const getRoles = () => {
    axios.get('roles?page=1&limit=100')
      .then((response) => {
        let data = response.data.map((role: IUserRoles) => {
          return {
            value: role.id,
            label: role.name
          }
        });

      setRoles(data)
    })
  }

  useEffect(() => {
    if(hasRoleViewPermission) getRoles()
  }, [hasRoleViewPermission])

  return (
    <div className="memberInfo">
      <div className={`memberInfo__image memberInfo__image--${user.status}`}>
        <div className={`memberInfo__image-text memberInfo__image-text--${user.status}`}>{user.status}</div>
        {
          user.profile_photo && <img src={`${process.env.REACT_APP_HOST}/public/users/${user.profile_photo}`} alt={user.full_name} />
        }
        {
          user.status === EUserStatus.REGISTERED && <img className='registeredDefaultImage' src={RegisteredDefaultImage} alt={user.full_name} />
        }
        
      </div>
      <div className="memberInfo__container">
        <div className="memberInfo-info">
          
          
          <div className="memberInfo-info__item">
            <div className="memberInfo-info__item-label">Full name</div>
            <div className="memberInfo-info__item-text">{user.full_name || '-'}</div>
          </div>

          <div className="memberInfo-info__item">
            <div className="memberInfo-info__item-label">Email</div>
            <div className="memberInfo-info__item-text">{user.email || '-'}</div>
          </div>

          {
            user.identity_type === 'id' && (
              <>
                <div className="memberInfo-info__item">
                  <div className="memberInfo-info__item-label">State</div>
                  <div className="memberInfo-info__item-text">{user.state || '-'}</div>
                </div>
                
                <div className="memberInfo-info__item">
                  <div className="memberInfo-info__item-label">ID Number</div>
                  <div className="memberInfo-info__item-text">
                    {user.id_number || '-'}
                  </div>
                </div>
              </>
            )
          }

        {
            user.identity_type === 'passport' && (
              <>
                <div className="memberInfo-info__item">
                  <div className="memberInfo-info__item-label">Country</div>
                  <div className="memberInfo-info__item-text">{user.country || '-'}</div>
                </div>
                
                <div className="memberInfo-info__item">
                  <div className="memberInfo-info__item-label">ID Number</div>
                  <div className="memberInfo-info__item-text">
                    {user.passport_or_driver_license || '-'}
                  </div>
                </div>
              </>
            )
          }

          <div className="memberInfo-info__item">
            <div className="memberInfo-info__item-label">Phone</div>
            <div className="memberInfo-info__item-text">{user.phone || '-'}</div>
          </div>

          

          <div className="memberInfo-info__item">
            <div className="memberInfo-info__item-label">Birth day</div>
            <div className="memberInfo-info__item-text">
              {user?.dob?.toString().split('T')[0] || '-'}
            </div>
          </div>

          <div className="memberInfo-info__item">
            <div className="memberInfo-info__item-label">Social Links</div>
            <div className="memberInfo-info__item-text">
              <SocialIconsComponent socialLinks={user.social_links} />
            </div>
          </div>

          {
            user.status === EUserStatus.VERIFIED && (
              <PermissionGuard role={PermissionsEnum.FRIEND_INVITATION}>
                <div className="memberInfo-info__item">
                  <div className="memberInfo-info__item-label">Friend Invitation</div>
                  <div className="memberInfo-info__item-text">
                    <label>
                      <Toggle
                        defaultChecked={user.can_invitation ? true : false}
                        onChange={handleInvitationToggle} 
                        disabled={isToggleDisabled}
                      />
                    </label>
                  </div>
                </div>
              </PermissionGuard>
            )
          }
        </div>
        <div className='w-50p'>
          <InputContainer>
            <br />
            {
              (hasShowMemberRolePermission || hasRoleViewPermission) && <label className='member-role-select' htmlFor='member-role-select'>Role</label>
            }
            {
              (hasShowMemberRolePermission && !hasRoleViewPermission) && <div style={{ color: '#fff' }}>{user?.role?.name}</div>
            }
            
            <ConfirmPopup 
              isopen={isPopupOpen} 
              onAction={onPopupAction}
              text={'Are you sure you want to change the role of this user?'} 
              label='Role change'
            />
            {
              hasRoleViewPermission && 
                <Select 
                  options={roles} 
                  onChange={(data) => {
                    setIsPopupOpen(true)

                    if(data) {
                      setRole(data)
                    }
                  }}
                  value={selectValue}

                  id="member-role-select"
                  styles={{
                      control: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: 'transparent',
                          height: '40px',
                          borderColor: '#272727',
                          borderRadius: '6px',
                          color: '#FFF',
                      }),
                      singleValue: (baseStyles) => ({
                          ...baseStyles,
                          color: '#FFF',
                          outline: 'none'
                      }),
                      input: (baseStyles) => ({
                          ...baseStyles,
                          color: '#FFF'
                      }),
                  }}
                />
            }
            
          </InputContainer>
        </div>
      </div>
    </div>
  );
};

export default MemberInfo;
