import './ProfileIcon.scss';

import { useEffect, useState } from "react";

interface IProfileIconComponentProps {
    name: string;
}

export const ProfileIconComponent: React.FC<IProfileIconComponentProps> = ({name}) => {
    const [splitName, setSplitName] = useState<string>('');

    useEffect(() => {
        const words = name.split(' ');

        const initials = words.map(word => word.charAt(0));

        let result = initials?.join('');

        result = result?.substring(0, 2);
        
        setSplitName(result);
    }, [name])
    
    return (
        <div className="splitName">{splitName}</div>
    )
}