import  styledComponent from "styled-components";

export const StyledTitle = styledComponent.h1`
    font-size: 24px;
    font-family: 'Main Bold', sans-serif;
    margin-bottom: 35px;
    min-height: 39px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .link {
        font-size: 16px;
        text-decoration: none;
        color: #fff;
        font-family: 'Main Regular', sans-serif;
        transition: all 0.3s ease-in-out;
        &:hover {
            text-decoration: underline;
        }
    }
`;