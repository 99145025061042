import TooltipComponent from "../Tooltip/Tooltip.component";
import StyledTextarea, { ITextarea, TextareaContainer } from "./Textarea.styles";

const Textarea : React.FC<ITextarea> = ({ variant, label, id, error, tooltip, showRequired, ...rest }) => {
    
    return (
        <TextareaContainer>
            {
                label && <label htmlFor={id}>
                    {label}
                    {showRequired && <span className="required-field"> *</span>}
                    {
                        tooltip && <TooltipComponent title={tooltip} />
                    }
                </label>
            }
            <StyledTextarea error={error} variant={variant} id={id} {...rest} />
            {error && <div className='input-error'>{error}</div>}
        </TextareaContainer>
    );
};

export default Textarea ;