import React from 'react';
import './Loader.scss';

export interface LoaderProps {
    onFull?: boolean;
    space?: 'sm'
}

export const Loader: React.FC<LoaderProps> = ({onFull= false, space= null}) => {
    return (
        <div className={`loader ${space && `loader--${space}`} ${onFull && 'loader--full'}`}>
            <div className="dots"></div>
        </div>
    );
};