import { useEffect, useState } from "react"
import Title from "../../../Components/Title/Title.component"
import Layout from "../../../Views/Admin/Layout"
import './LostAndFoundDetail.scss'
import axios, { AxiosError, AxiosResponse } from "axios"
import { useParams } from "react-router-dom"
import { Loader } from "../../../Components/Loader/Loader.component"
import moment from "moment"
import { PermissionsEnum } from "../../../utils/interfaces/permissions.enum"
import { StateSwitch } from "../../../Components/StateSwitch/StateSwitch.component"
import { PermissionGuard } from "../../../Components/PermissionGuard/PermissionGuard.component"

interface LAFResponse{
    id: string,
    fullName: string,
    phone: string,
    email: string,
    ipAddress: string,
    item: string,
    description: string,
    status: "lost" | "found" | "not_found",
    createdAt: string,
    updatedAt: string
}

export const LostAndFoundDetail = () => {
    const { id } = useParams();
    const [ data, setData] = useState<LAFResponse | undefined>()
    const [ checkbox, setCheckbox ] = useState<LAFResponse["status"]>();

    useEffect( ()=> {
        axios.get(`/lostandfound/${id}`)
            .then((data:AxiosResponse) => {
                setData(data.data)
                setCheckbox(data.data.status)
            })
            .catch((e:AxiosError) => {
                console.error(e);
            })
    },[id]);

    const handleClick = (value:LAFResponse["status"]) => {
        if(!value) return;
        axios.patch(`lostandfound/${id}`,{status:value})
            .then(() => {
                setCheckbox(value)
            })
            .catch((e)=>{
                console.log(e);
            })
    }
    
    return (
        <Layout>
            <Title title={`Case from ${data?.fullName ?? ""}`} />
            {
                data ? (
                    <div className="lf">
                        <div className="lf__row">
                            <div className="lf__col">
                                <span className="lf__name">Item</span>
                                <span className="lf__value">{data.item}</span>
                            </div>
                            <div className="lf__col">
                                <span className="lf__name">Phone</span>
                                <span className="lf__value">{data.phone}</span>
                            </div>
                            <div className="lf__col">
                                <span className="lf__name">Email</span>
                                <span className="lf__value">{data.email}</span>
                            </div>
                            <div className="lf__col">
                                <span className="lf__name">Create at</span>
                                <span className="lf__value">{moment(data.createdAt).format('YYYY/MM/DD')}</span>
                            </div>
                        </div>
                        <div className="lf__col">
                            <span className="lf__name">Comment</span>
                            <p className="lf__text">{data.description}</p>
                        </div>

                        <PermissionGuard role={PermissionsEnum.UPDATE_LOST_AND_FOUND_STATUS}>
                            <div className="">
                                <StateSwitch 
                                    status={checkbox as LAFResponse["status"]} 
                                    onClick={(value) => {
                                        handleClick(value)
                                    }}
                                />
                            </div>
                        </PermissionGuard>
                        
                    </div>  
                ) : <Loader />
            }
        </Layout>
    )
}