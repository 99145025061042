import './ConfirmPopup.scss';
import PopupWrapper, { StyledPopupHeader } from '../../Views/Admin/Popup.wrapper';

const ConfirmPopup: React.FC<{
  isopen: boolean;
  text: string;
  onAction: (action: boolean) => void;
  label: string;
}> = ({ isopen, text, onAction, label }) => {
  return (
    <PopupWrapper isopen={isopen}>
      <StyledPopupHeader>
        <div className="popup-title">{label}</div>
        <div className="close" onClick={() => onAction(false)}></div>
      </StyledPopupHeader>
      <div className="statusChange">

        <div className='statusChange__text'>{text}</div>

        <div className='statusChange__buttons'>
            <button type="button" className='statusChange__button statusChange__button--yes' onClick={() => onAction(true)}>YES</button>
            <button type="button" className='statusChange__button statusChange__button--no' onClick={() => onAction(false)}>NO</button>
        </div>
        
      </div>
    </PopupWrapper>
  );
};

export default ConfirmPopup;
