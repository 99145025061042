import './ImageCard.scss';
import { useState } from 'react';

type ImageCardType = {
    url: string,
    alt: string,
    onClick?: () => void
}

export const ImageCard = ({
    url,
    alt,
    onClick
}:ImageCardType) => {
    const [ loading, setLoading ] = useState(true);

    return <div className="imagecard" onClick={onClick}>
        <img className={`imagecard__img ${loading && 'hidden'}`} loading="lazy" src={url} alt={alt} onLoad={()=>{setLoading(false)}}/>
    </div>
}