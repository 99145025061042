import PopupWrapper, { StyledPopupHeader } from '../../Views/Admin/Popup.wrapper';
import { IUser } from '../../utils/interfaces/user.interface';
import RequestComponent from '../RequestComponent/RequestComponent';

const LetterPopup: React.FC<{
  isopen: boolean;
  text: string;
  onClose: () => void;
  user: IUser
}> = ({ isopen, text, onClose, user }) => {
  return (
    <PopupWrapper isopen={isopen}>
      <StyledPopupHeader>
        <div className="popup-title">Letter from {user.invited_by?.full_name}</div>
        <div className="close" onClick={onClose}></div>
      </StyledPopupHeader>
      <div className="letter-popup">
        {text}
        <br />
        <br />
        <RequestComponent 
            bg={'transparent'}
            text={user.invited_by?.full_name || ''}
            image={`${process.env.REACT_APP_HOST}/public/users/${user.invited_by?.profile_photo}`}
        />
      </div>
    </PopupWrapper>
  );
};

export default LetterPopup;
