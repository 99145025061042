import { RequireAuth } from "react-auth-kit";
import { createBrowserRouter } from 'react-router-dom';
import LoginPage from "./Pages/Admin/Login/Login.page";
import DashboardPage from "./Pages/Admin/Dashboard/Dashboard.page";
import MembersPage from "./Pages/Admin/Members/Members.page";
import MembersProfilePage from "./Pages/Admin/MembersProfile/MembersProfile.page";
import LoginMember from "./Pages/LoginMember/LoginMember.page";
import RegistrationMember from "./Pages/RegistrationMember/RegistrationMember.page";
import ActivateAccount from "./Pages/ActivateAccount/ActivateAccount.page";
import Activate from "./Pages/Activate/Activate.page";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import SuccessPage from "./Pages/SuccessPage/SuccessPage";
import RecoverPassword from "./Pages/RecoverPassword/RecoverPassword";
import CompleteRegistration from "./Pages/CompleteProfile/CompleteProfile.page";
import VerificationPending from "./Pages/VerificationPending/VerificationPending.page";
import Profile from "./Pages/Profile/Profile.page";
import InvitesPage from "./Pages/Admin/Invites/Invites.page";
import EventsPage from "./Pages/Admin/Events/Events.page";
import EventDetail from "./Pages/Admin/EventDetail/EventDetail.page";
import EventCreate from "./Pages/Admin/EventCreate/EventCreate.page";
import ResidentsPage from "./Pages/Admin/Residents/Residents.page";
import ResidentsCreate from "./Pages/Admin/ResidentsCreate/ResidentsCreate.page";
import ResidentUpdate from "./Pages/Admin/ResidentDetail/ResidentDetail.page";
import ContentPage from "./Pages/Admin/Content/Content.page";
import ContentDetail from "./Pages/Admin/ContentDetail/ContentDetail.page";
import { UpcommingEvents } from "./Pages/Public/Events/UpcomingEvents";
import { PastEvents } from "./Pages/Public/Events/PastEvents";
import EventDetailPage from "./Pages/Public/EventDetail/EventDetail";
import { Residents } from "./Pages/Public/Residents/Residents";
import ResidentDetailPage from "./Pages/Public/ResidentDetail/ResidentDetail";
import HomeRulesPage from "./Pages/Public/HomeRulesPage/HomeRules";
import MainLayout from "./layout/Main/Main.layout";
import { LostAndFound } from "./Pages/Admin/LostAndFound/LostAndFound.page";
import { LostAndFoundDetail } from "./Pages/Admin/LostAndFoundDetail/LostAndFoundDetail.page";
import { UserRoles } from "./Pages/Admin/UserRoles/UserRoles.page";
import { UserRolesEdit } from "./Pages/Admin/UserRolesEdit/UserRolesEdit.page";
import { UserRolesCreate } from "./Pages/Admin/UserRolesCreate/UserRolesCreate.page";


export const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout/>,
      children: [
        {
          path: "login",
          element: <LoginMember/> ,
        },
        {
          path: "registration",
          element: <RegistrationMember/> ,
        },
        {
          path: "activate-account",
          element: <ActivateAccount/> ,
        },
        {
          path: "activate",
          element: <Activate/> ,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword/> ,
        },
        {
          path: "reset-password",
          element: <RecoverPassword/> ,
        },
        {
          path: "success",
          element: <SuccessPage/> ,
        },
        {
          path: "complete-registration",
          element: <RequireAuth loginPath="/login"><CompleteRegistration /></RequireAuth> ,
        },
        {
          path: "verification-pending",
          element: <RequireAuth loginPath="/login"><VerificationPending /></RequireAuth> ,
        },
        {
          path: "profile",
          element: <RequireAuth loginPath="/login"><Profile /></RequireAuth>,
        },
        {
          path: "past",
          element: <PastEvents/> ,
        },
        {
          path: "events/:id",
          element: <EventDetailPage/> ,
        },
        {
          path: "residents",
          element: <Residents/> ,
        },
        {
          path: "residents/:id",
          element: <ResidentDetailPage/> ,
        },
        {
          path: "house-rules",
          element: <HomeRulesPage/> ,
        },
        {
          path: "/",
          element: <UpcommingEvents/> ,
        },
        {
          path: "/*",
          element: <UpcommingEvents/> ,
        },
      ]
    },
    //ADMIN
    {
      path:"admin",
      children:[
        {
          path:"",
          element: <RequireAuth loginPath="/admin/login"><DashboardPage /></RequireAuth>,
        },
        {
          path:"members",
          element: <RequireAuth loginPath="/admin/login"><MembersPage /></RequireAuth>,
        },
        {
          path:"members/:member_id",
          element: <RequireAuth loginPath="/admin/login"><MembersProfilePage /></RequireAuth>,
        },
        {
          path:"invites",
          element: <RequireAuth loginPath="/admin/login"><InvitesPage /></RequireAuth>,
        },
        {
          path:"lost-and-found",
          element: <RequireAuth loginPath="/admin/login"><LostAndFound /></RequireAuth>,
        },
        {
          path:"lost-and-found/:id",
          element: <RequireAuth loginPath="/admin/login"><LostAndFoundDetail /></RequireAuth>,
        },
        {
          path:"user-roles",
          element: <RequireAuth loginPath="/admin/login"><UserRoles /></RequireAuth>,
        },
        {
          path:"user-roles/:id",
          element: <RequireAuth loginPath="/admin/login"><UserRolesEdit /></RequireAuth>,
        },
        {
          path:"user-roles/create",
          element: <RequireAuth loginPath="/admin/login"><UserRolesCreate /></RequireAuth>,
        },
        {
          path:"events",
          element: <RequireAuth loginPath="/admin/login"><EventsPage /></RequireAuth>,
        },
        {
          path:"events/:event_id",
          element: <RequireAuth loginPath="/admin/login"><EventDetail /></RequireAuth>,
        },
        {
          path:"events/create",
          element: <RequireAuth loginPath="/admin/login"><EventCreate /></RequireAuth>,
        },
        {
          path:"residents",
          element: <RequireAuth loginPath="/admin/login"><ResidentsPage /></RequireAuth>,
        },
        {
          path:"residents/create",
          element: <RequireAuth loginPath="/admin/login"><ResidentsCreate /></RequireAuth>,
        },
        {
          path:"residents/:resident_id",
          element: <RequireAuth loginPath="/admin/login"><ResidentUpdate /></RequireAuth>,
        },
        {
          path:"content",
          element: <RequireAuth loginPath="/admin/login"><ContentPage /></RequireAuth>,
        },
        {
          path:"content/:content_key",
          element: <RequireAuth loginPath="/admin/login"><ContentDetail /></RequireAuth>,
        },
        {
          path:"login",
          element: <LoginPage />,
        },
      ]
    }
]);