import Footer from '../../Views/Footer/Footer';
import { Outlet, ScrollRestoration } from "react-router-dom";
import './Main.layout.scss';
import HeaderNew from '../../Views/HeaderNew/HeaderNew';

const MainLayout: React.FC<{children?: React.ReactNode}> = ({children}) => {
    return (
        <>
            <ScrollRestoration getKey={(location) => {
                const path = "/events/";
                return location.pathname.startsWith(path) ? null : location.key
             }}/>
            <div className="main-layout main-container">
                <HeaderNew />
                    <main className="main-layout__content">
                        <Outlet />
                    </main>
                <Footer/>
            </div>
        </>
    )
};

export default MainLayout;