import  styledComponent from "styled-components";
import { useContext, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { IUser } from "../../utils/interfaces/user.interface";
import LoadingWrapper from "../../Views/Admin/Loading.wrapper";
import { EUserStatus } from "../../utils/interfaces/user-status.enum";
import { useNavigate } from "react-router-dom";
import { useSignOut } from "react-auth-kit";
import { Button } from "../../Components/Button/Button.component";
import { LangsContext } from "../../context/LangsContext";

const VerificationPendingStyles = styledComponent.div`
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #272727;
    border-radius: 6px;
    width: 690px;
    margin: 0 auto;
    margin-top: 45px;
    padding: 40px 60px;
    .title {
        width: 100%;
        text-align: left;
    }
    .desc {
        margin: 0 auto;
        display: table;
        &__title {
            color: #fff;
            padding: 30px 0;
            font-size: 32px;
            text-align: center;
            padding-top: 70px;
            background: url(${require("../../assets/images/waiting.svg").default}) no-repeat center top;
        }
        &__text {
            font-size: 16px;
            color: #fff;
            text-align: center;
        }
    }
    @media (max-width: 768px) {
        width: 100%;
        padding: 20px 30px;
        .title {
            margin-bottom: 75px;
        }
        .desc {
            &__title {
                font-size: 18px;
            }
        }
    }
`

const VerificationPending: React.FC = () => {
    const [user, setUser] = useState<IUser>();
    const [loading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const signOut: () => void = useSignOut();

    const content = useContext(LangsContext);

    const getMe = async () => {
        await axios.get(`users/me`)
            .then((res: AxiosResponse<IUser>) => {
                const {data} = res;

                if(data.status === EUserStatus.PENDING) {
                    setUser(data);
                }
                else if(data.status === EUserStatus.REGISTERED) {
                    navigate('/activate-account')
                }
                else {
                    navigate('/profile')
                }

                setIsLoading(false)
            })
    }

    const logOut = (): void => {
        signOut();
        navigate('/login');
    };

    useEffect(() => {
        getMe();
        // eslint-disable-next-line
    }, [])
    
    return (
        <VerificationPendingStyles>
            <LoadingWrapper loading={loading}>
                <div data-user={user?.email} className="title">
                    {content?.WAITING_FOR_APPROVAL || 'Waiting for approval'}
                </div>                

                <div className="desc">
                    <div className="desc__title">
                        {content?.YOUR_REQUEST_HAS_BEEN_SUBMITTED || 'Your request has been submitted'}
                    </div>
                    {/* <div className="desc__text">Most requests are reviewed within one week.</div> */}
                </div>
                <div>&nbsp;</div>
                <div className="flex jcc">
                    <Button className="profileDataBlocks__button" onClick={logOut} variant="stroked-red" type='button'>
                        {content?.LOGOUT || 'Logout'}
                    </Button>
                </div>
            </LoadingWrapper>
        </VerificationPendingStyles>
    )
}

export default VerificationPending;