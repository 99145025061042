import './ContentDetail.scss';

import Title from "../../../Components/Title/Title.component";
import Layout from "../../../Views/Admin/Layout";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import LoadingWrapper from '../../../Views/Admin/Loading.wrapper';
import Input from '../../../Components/Input/Input.component';
import "react-datepicker/dist/react-datepicker.css";
import { InputContainer } from '../../../Components/Input/Input.styles';
import { FormikValues, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { StyledButton } from '../../../Components/Button/Button.styles';
import { IContent } from '../../../utils/interfaces/content.interface';
import { PermissionGuard } from '../../../Components/PermissionGuard/PermissionGuard.component';
import { PermissionsEnum } from '../../../utils/interfaces/permissions.enum';
import { usePermissionGuard } from '../../../hooks/usePermissionsGuard';
import { toastOptions } from '../../../utils/helpers/toast.options';

const ContentDetail: React.FC = () => {
    const {content_key} = useParams<{content_key: string}>();
    const [event, setContent] = useState<IContent | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const canUpdate = usePermissionGuard(PermissionsEnum.UPDATE_CONTENT)

    const getContentInfo = async (): Promise<void> => {
        if(event) return;
        
        await axios.get(`/content/${content_key}`)
            .then((res: AxiosResponse) => {
                const {data} = res;

                formik.setFieldValue('key', data.key);
                formik.setFieldValue('value', data.value);

                setContent(data);
                setIsLoading(false);
            })
    }
    
    const onSubmit = async (values: any) => {
        setIsLoading(true);
        
        try {
            await axios.patch(`content/${content_key}`, values)
                .then(() => {
                    toast.success(`Text updated 🦄`, toastOptions)
                });
        } catch (err) {
            if (err && err instanceof AxiosError) {
                if(err.response?.data?.message === 'INVALID_CREDENTIALS') {
                    setError('Incorrect email or password.');
                }
                else {
                    setError(err.response?.data?.message);
                }
            }
            else if (err && err instanceof Error) {
                setError('Something went wrong. Please try again later.');
            }

            console.log("Error: ", err);
        }

        setIsLoading(false);
    };

    const formik: FormikValues = useFormik({
        initialValues: {
            value: ''
        },
        onSubmit,
    });


    useEffect(() => {
        getContentInfo();
        // eslint-disable-next-line
    }, [content_key])
    
    return (
        <Layout>
            <LoadingWrapper loading={isLoading}>
                <div className="membersProfile">
                    <div className="membersProfile-info">
                        <Title title={'Text management'} />
                        <form onSubmit={formik.handleSubmit}>
                            <Input 
                                type='text'
                                placeholder='Type event name'
                                className='loginForm__input loginForm__input--noRounded'
                                label='Key name'
                                disabled={true}
                                name='key'
                                error={formik.touched.key && formik.errors.key}
                                value={formik.values.key}
                                onChange={formik.handleChange}
                            />

                        <InputContainer>
                                <label htmlFor='value'>Value</label>

                                <textarea 
                                    placeholder='Text value'
                                    className='letter-popup__textarea letter-popup__textarea--simple'
                                    name='value'
                                    id="value"
                                    disabled={!canUpdate}
                                    onChange={formik.handleChange}
                                    value={formik.values.value}
                                />

                                {formik.errors.value && <div className='error'>{formik.errors.value}</div>}
                            </InputContainer>
                    

                            {error && <div className='error-message'>{error}</div>}

                            <PermissionGuard role={PermissionsEnum.UPDATE_CONTENT}>
                                <StyledButton type={'submit'} variant='red' className='sm'>update text</StyledButton>
                            </PermissionGuard>

                            <br />
                            <br />
                        </form>
                    </div>
                </div>
            </LoadingWrapper>
        </Layout>
    )
}

export default ContentDetail;