import { NavLink } from 'react-router-dom';
import './Statistics.scss'
import { usePermissionGuard } from '../../hooks/usePermissionsGuard';
import { PermissionsEnum } from '../../utils/interfaces/permissions.enum';

export interface IStatisticsComponent {
    verified: number;
    pending: number;
    requested: number;
    banned: number;
    all: number;
}
const StatisticsComponent: React.FC<IStatisticsComponent>  = ({
    verified,
    pending,
    requested,
    banned,
    all
}) => {
    const haveRole = usePermissionGuard(PermissionsEnum.MEMBERS_PAGE_VIEW);
    
    return (
        <div className='statistics'>
            <div className='statistics__container'>
                <NavLink to={haveRole ? `/admin/members?status=VERIFIED` : '/admin'} className='statistics__item statistics__item--verified'>
                    <div className='statistics__item-title'>
                        Verified
                    </div>
                    <div className='statistics__item-count'>
                        {verified}
                    </div>
                </NavLink>
                <NavLink to={haveRole ? '/admin/members?status=PENDING' : '/admin'} className='statistics__item statistics__item--pending'>
                    <div className='statistics__item-title'>
                        Pending
                    </div>
                    <div className='statistics__item-count'>
                        {pending}
                    </div>
                </NavLink>
            </div>
            <div className='statistics__container'>
                <NavLink to={haveRole ? `/admin/members?status=UNVERIFIED` : '/admin'} className='statistics__item statistics__item--requested'>
                    <div className='statistics__item-title'>
                        Unverified
                    </div>
                    <div className='statistics__item-count'>
                        {requested}
                    </div>
                </NavLink>
                <NavLink to={haveRole ? `/admin/members?status=BLOCKED` : '/admin'} className='statistics__item statistics__item--banned'>
                    <div className='statistics__item-title'>
                        Banned
                    </div>
                    <div className='statistics__item-count'>
                        {banned}
                    </div>
                </NavLink>
            </div>
            <div className='statistics__container'>
                <NavLink to={haveRole ? `/admin/members?status=ALL` : '/admin'} className='statistics__item statistics__item--all'>
                    <div className='statistics__item-title'>
                        See all
                    </div>
                    <div className='statistics__item-count'>
                        {all}
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

export default StatisticsComponent;