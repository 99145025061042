export function convertDate(
  dateStr: string,
  is_time_required: boolean = false
): string {
  const months = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ];

  const parts = dateStr.split('-');
  const year = parts[0];
  const month = months[parseInt(parts[1]) - 1];
  const day = parseInt(parts[2]);

  let formattedDate = `${month} ${day}, ${year}`;

  if (is_time_required) {
    const timeStr = dateStr.split(' ')[1];
    formattedDate += ` - ${timeStr}`;
  }

  return formattedDate;
}
