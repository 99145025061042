import ProfileData from "../../Components/ProfileData/ProfileData.component";
import { IUser } from "../../utils/interfaces/user.interface";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import ProfileInvites from "../../Views/ProfileInvites/ProfileInvites.view";
import { EUserStatus } from "../../utils/interfaces/user-status.enum";
import { useNavigate } from "react-router-dom";
import './Profile.page.scss';

const Profile: React.FC = () => {
    const [user, setUser] = useState<IUser>();
    const [loading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate()

    const getMe = async () => {
        await axios.get(`users/me`)
            .then((res: AxiosResponse<IUser>) => {
                const {data} = res;

                if(data.status === EUserStatus.PENDING) {
                    navigate('/verification-pending')
                }
                else {
                    setUser(data);
                    setIsLoading(false);
                }
            })
    }

    useEffect(() => {
        getMe();
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            {
                loading ? '': (
                    <div className="flex flex-jcsb profileContainer">
                        <ProfileData user={user as IUser} />
                        <ProfileInvites user={user as IUser} />
                    </div>
                )
            }
        </div>
    )
}

export default Profile;