import  styledComponent from "styled-components";
import { NavLink, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import LoadingWrapper from "../../Views/Admin/Loading.wrapper";
import { LangsContext } from "../../context/LangsContext";

const ActivateStyles = styledComponent.div`
    text-align: center;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .text:nth-child(2) {
        margin-top: 30px;
        margin-bottom: 60px;
    }
`

const Activate: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    const content = useContext(LangsContext);

    const hash = searchParams.get('hash');

    useEffect(() => {
        if(hash) {
            axios.post(`${process.env.REACT_APP_HOST}/users/activate?hash=${hash}`)
                .then(() => setLoading(false))
                .catch(() => setError('Invalid activation link'))
        }
        else {
            setError('Invalid activation link');
        }
    }, [hash])

    return (
        <ActivateStyles>
            <LoadingWrapper loading={loading}>
                {
                    !error && (
                        <>
                            <div className="text text--lg text--center text--bold">{content?.YOUR_EMAIL_ACTIVATED || 'Your email successfully activated.'}</div>                

                            <div className="text text--sm text--center">{content?.LOGIN_AND_VERIFY || 'Login to your account and verify your profile.'}</div>
                        </>
                    )
                }

                {error && <div className="text text--sm text--center text--error">{error}</div>}

                {!error && <NavLink to="/login" className="loginForm__link loginForm__link--login">{content?.LOGIN || 'Login'}</NavLink>}
            </LoadingWrapper>
        </ActivateStyles>
    )
}

export default Activate;